import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import { Layout, Alert, Anchor, Drawer, Button, Typography } from 'antd';
import { useScreen } from '../Contexts';

import { siteName, companyName, siteUrlNoSlash } from '../../constants';
import LandingFooter from '../Landing/footer';
import { CloseOutlined } from '@ant-design/icons';
import { Capacitor } from '@capacitor/core';

import { Helmet } from "react-helmet";

const isNative = Capacitor.isNativePlatform();


const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

const markdownFiles = {
    terms: 'terms',
    about: 'about',
    privacy: 'privacy',
    // disclaimer: disclaimer
};

const docTitles = {
    terms: `Terms of Service | ${siteName}`,
    about: `About Us | ${siteName}`,
    privacy: `Privacy Policy | ${siteName}`,
};


const docDescriptions = {
    terms: `Read the Terms of Service of ${siteName} to understand the rules and regulations that govern your use of our services, including the website and mobile apps.`,
    about: `Discover more about ${siteName}, and why it is an innovative approach to time tracking.`,
    privacy: `Learn about how ${siteName} collects, uses, stores, and protects your data in our detailed Privacy Policy.`,
};


// Function to convert heading text to a URL-friendly ID
const generateId = text => text?.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

const markdownComponents = {
    blockquote: ({ node, children, ...props }) => (
        <Alert
            type="warning"
            message={children}
            style={{ marginBottom: '1em' }}
            {...props}
        />
    ),
    h2: ({ node, children, ...props }) => <h2 id={generateId(children)} {...props} >{children}</h2>,
};



const siderStyle = {
    textAlign: 'left',  // Align text to the left for better readability of wrapped titles
    color: '#fff',
    backgroundColor: '#00152a',
    padding: '10px',
    overflowY: 'auto',  // Allows vertical scrolling if the list is long
    minWidth: '360px',
};


const h4Style = {
    position: 'fixed',
    top: 50,             // Adjusts how far from the top the item should become sticky
    // backgroundColor: '#00152a', // Ensures the background color fills the sticky header
    padding: '10px 0',  // Adds vertical padding within the header
    borderBottom: '1px solid #fff', // Adds a separator between the title and the content
    zIndex: 1,          // Ensures the header stays on top of other content
    color: '#fff',
};



const DocMD = ({ path }) => {

    const [text, setText] = useState('');
    const [anchors, setAnchors] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false); // State to toggle sidebar visibility
    const { width: screenWidth } = useScreen();
    const isNarrowScreen = screenWidth < 768;
    const isWideScreen = screenWidth >= 1200;


    const replacePlaceholders = markdownText => {
        const replacements = {
            '{SITE_NAME}': siteName,
            '{SITE_URL}': siteUrlNoSlash,
            '{COMPANY_NAME}': companyName,
        };

        return markdownText.replace(/\{SITE_NAME\}|\{SITE_URL\}|\{COMPANY_NAME\}/g, match => replacements[match]);
    };


    const onClose = () => {
        setSidebarVisible(false);
    };



    useEffect(() => {
        // Automatically hide the sidebar when the screen is narrow
        setSidebarVisible(!isNarrowScreen);
    }, [isNarrowScreen]);

    // const { isDarkMode } = useTheme();

    useEffect(() => {

        const extractH2Anchors = markdownText => {
            // only extract h2 headings
            const regex = /^##\s+(.+)$/gm;
            let match;
            const anchors = [];
            while ((match = regex.exec(markdownText)) !== null) {
                const text = match[1].trim();  // Capture the heading text
                const id = generateId(text);  // Generate a URL-friendly ID based on the text
                anchors.push({ id, text, level: 2 });  // Store the information, assuming level 2 for h2
            }
            return anchors;
        };

        const generateId = text => text.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

        const fetchMarkdown = async (path) => {
            let url;
            const docType = markdownFiles[path];
            if (isNative) {
                // Fetch from an external server in native platforms
                url = `${siteUrlNoSlash}/docs/${docType}.md`;
                // console.log('fetching from', url);
            } else {
                // Fetch locally in web app
                url = `${process.env.PUBLIC_URL}/docs/${docType}.md`;
            }
            try {
                const response = await fetch(url);
                const markdownText = await response.text();
                return markdownText;
            } catch (error) {
                console.error('Failed to fetch markdown', error);
                return '';
            }
        };

        fetchMarkdown(path)
            .then(markdownText => {
                const fullyReplacedText = replacePlaceholders(markdownText);
                setText(fullyReplacedText);

                // Extract headings and generate anchors
                const extractedAnchors = extractH2Anchors(fullyReplacedText); // Implement this function
                setAnchors(extractedAnchors);
            }
            );

        // // change document title for SEO
        // document.title = docTitles[path];

    }, [path]);



    return (


        <Layout
            style={{
                display: 'flex',
                flexDirection: 'column',
                // height: '100%', // Use 100% height for the flex container
                minHeight: '100vh', // minHeight here only to fill the viewport initially

            }}
        >

            <Helmet>
                <title>{docTitles[path]}</title>
                <meta name="description" content={docDescriptions[path]} />
                <meta property="og:title" content={docTitles[path]} />
                <meta property="og:description" content={docDescriptions[path]} />
                <meta property="og:url" content={`${siteUrlNoSlash}/${path}`} />
                <meta property="twitter:title" content={docTitles[path]} />
                <meta property="twitter:description" content={docDescriptions[path]} />
            </Helmet>

            <Content>

                {isNarrowScreen && <Button type="primary"
                    onClick={() => setSidebarVisible(true)}
                    style={{
                        position: 'fixed',
                        zIndex: 1000,
                        top: 46,
                        right: 20,
                        borderRadius: '10px'
                    }}
                >
                    ToC
                </Button>}

                <Layout>


                    {isNarrowScreen ?
                        <Drawer
                            title={<Title level={4} style={{ marginTop: 30 }}>Table of Contents</Title>}
                            open={sidebarVisible}
                            onClose={onClose}
                            placement="left"
                            closeIcon={<CloseOutlined style={{ marginTop: 20, fontSize: 20 }} />}
                        >
                            {/* <h4 style={h4Style} >Table of Contents</h4> */}

                            <Anchor
                                offsetTop={100}
                                items={anchors.map((a, i) => ({ key: i, href: '#' + a.id, title: a.text }))}
                                onClick={() => setSidebarVisible(false)}
                            />
                        </Drawer> :
                        <Sider

                            width={isWideScreen ? 600 : 400}
                            style={siderStyle}

                        >
                            <h4 style={h4Style} >Table of Contents</h4>

                            <Anchor
                                className='toc-sider-anchor'
                                offsetTop={140}
                                items={anchors.map((a, i) => ({ key: i, href: '#' + a.id, title: a.text }))}
                            />
                        </Sider>

                    }



                    <Content
                        style={{
                            flex: 1,  // Allows content to expand and fill available space
                            display: "flex",
                            flexDirection: "column",
                            overflow: 'auto', // Allows content to scroll if needed
                            padding: 24,
                            marginTop: 20,
                            // padding: '60px 24px 24px 24px', // Increase top padding to prevent overlap
                        }}

                    >


                        <ReactMarkdown
                            components={markdownComponents}
                        >{text}</ReactMarkdown>
                    </Content>

                </Layout>

            </Content >

            <Footer
                style={{
                    // height: footerHeight,
                    position: 'sticky',
                    bottom: 0,
                    // padding: '10px 10px 20px 10px',
                    padding: 0,
                    borderTop: '1px solid #f0f0f0',
                }}
            >
                <LandingFooter />
            </Footer>


        </Layout >


    );

}

export default DocMD;


// const extractAnchors = markdownText => {
// extract all headings
//     const regex = /^(#{1,6})\s+(.+)$/gm;
//     let match;
//     const anchors = [];
//     while ((match = regex.exec(markdownText)) !== null) {
//         const level = match[1].length; // Determine heading level from hash count
//         const text = match[2].trim();
//         const id = generateId(text);
//         anchors.push({ id, text, level });
//     }
//     return anchors;
// };
