import React, { useContext, useEffect, useState, useCallback } from 'react';
import { notification, Button } from 'antd';
import { ref, update, get, set } from 'firebase/database';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext } from '../Contexts';
import { getToken, onMessage } from "firebase/messaging";
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';




function App() {

    const [notiApi, contextHolder] = notification.useNotification({ placement: 'top', top: 40, stack: { threshold: 2 }, maxCount: 2 });
    const [msg, setMsg] = useState(null);
    const [notificationPermission, setNotificationPermission] = useState(''); // This would track the permission status in cases it's denied

    const notiMsg = {
        'procrastination': {
            title: 'Turn on notifications to make better use of the app',
            body: 'Timely notifications help prevent procrastination.'
        },
        'habits': {
            title: 'Turn on notifications for habit building',
            body: 'Timely notifications support your habit development.'
        },
        'work': {
            title: 'Enable Notifications for Accuracy',
            body: 'Notifications ensure reliable logging of your important work activities.'
        },
        'productivity': {
            title: 'Enable notifications for productivity boosts',
            body: 'Timely notifications support consistent progress on your tasks.'
        },
        'myself': {
            title: 'Enable notifications for self-discovery',
            body: 'Notifications assist you in gathering insights that foster deeper self-understanding.'
        },
        'metrics': {
            title: 'Enable notifications to keep track of metrics',
            body: 'Notifications help you stay updated on your key metrics.'
        }
    };

    const isNative = Capacitor.isNativePlatform();

    const firebase = useContext(FirebaseContext);
    const { authUser } = useContext(AuthUserContext);
    const uid = authUser?.uid;

    const { db, messaging } = firebase;
    const platformKey = isNative ? Capacitor.getPlatform() : "web";

    function getBrowserHelpLink() {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Chrome")) {
            return 'https://support.google.com/chrome/answer/3220216?hl=en';
        } else if (userAgent.includes("Firefox")) {
            return 'https://support.mozilla.org/en-US/kb/push-notifications-firefox';
        } else if (userAgent.includes("Safari")) {
            return 'https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac';
        } else if (userAgent.includes("Edge")) {
            return 'https://support.microsoft.com/en-us/microsoft-edge';
        } else {
            return null;  // Default or unknown browser, could link to a generic help page you create
        }
    }



    const openSettings = () => {
        if (Capacitor.isNativePlatform()) {
            const platform = Capacitor.getPlatform();
            if (platform === 'ios') {
                // For iOS, direct users to the app-specific settings using the generic settings URL
                window.open('app-settings:', '_system');
            } else if (platform === 'android') {
                // For Android, use an Intent to open app-specific settings
                // This must be handled natively, here is just a placeholder
                console.log('Open Android settings for the app');
                // Typically, this would be handled via a custom native plugin or adjusted in the native project code
            }
        } else {
            const settingsLink = getBrowserHelpLink();
            window.open(settingsLink, '_blank');
        }
    }


    useEffect(() => {


        if (!uid || !db) return;

        const userSettingsRef = ref(db, `u_settings/${uid}`);
        const notificationSettingsRef = ref(db, `u_settings/${uid}/notification_nudge`);
        const userIntentRef = ref(db, `u_settings/${uid}/intent`);

        if (notificationPermission === 'denied') {
            get(userSettingsRef).then((snapshot) => {
                // to avoid having two popups, if the user hasn't seen the chat tour yet, do not show the permission reminder
                if (snapshot.exists() && snapshot.val().chat_tour_seen === true) {

                    const lastNudgeTime = snapshot.val().notification_nudge;
                    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

                    // only nudge if the last nudge was more than a week ago
                    if (!lastNudgeTime || lastNudgeTime < oneWeekAgo) {

                        get(userIntentRef).then((snapshot) => {
                            if (snapshot.exists()) {
                                const sval = snapshot.val(); // Assuming intent is stored as a string e.g., 'productivity'
                                const selectedOptions = sval['selectedOptions'] || []; // Convert the intent titles to an array
                                const freeText = sval['otherText'] || ''; // Get the free text input
                                const intents = freeText ? [...selectedOptions, freeText] : selectedOptions; // Combine the selected options and free text

                                console.log('User intents:', intents);

                                let foundMatch = false;

                                // Check for keywords in the order of priority
                                const keywordPriority = ['procrastination', 'habit', 'work', 'productivity', 'myself', 'metrics'];

                                for (let keyword of keywordPriority) {
                                    for (let intentTitle of intents) {
                                        if (intentTitle.toLowerCase().includes(keyword)) {
                                            setMsg({ ...notiMsg[keyword], withLink: true });
                                            foundMatch = true;
                                            break;
                                        }
                                    }
                                    if (foundMatch) break;
                                }

                                if (!foundMatch) {
                                    setMsg({
                                        title: 'Please enable notifications',
                                        body: 'You will not receive important updates without enabling notifications.',
                                        withLink: true
                                    });
                                }
                            }
                        });

                        // Update the last nudge time
                        set(notificationSettingsRef, Date.now());
                    }
                }
            });
        }


    }, [notificationPermission, db, uid]);



    useEffect(() => {

        if (!uid) return;

        const userPermissionRef = ref(db, `u_tokens/${uid}/${platformKey}`);

        const handleNativeNotifications = async () => {

            const permissionStatus = await PushNotifications.checkPermissions();

            // once permission has been denied, you cannot ask again -- won't be shown
            // only users can change this in their settings
            if (permissionStatus.receive === 'prompt') {

                const permission = await PushNotifications.requestPermissions();

                update(userPermissionRef, { asked: Date.now() })
                    // .then(() => console.log('Updated userPermissionRef with asked time'))
                    .catch((error) => console.error('Failed to update asked time:', error));

                if (permission.receive === 'granted') {
                    update(userPermissionRef, { granted: Date.now() })
                        // .then(() => console.log('Updated userPermissionRef with granted time'))
                        .catch((error) => console.error('Failed to update granted time:', error));
                    await registerDeviceAndListeners();
                } else {
                    update(userPermissionRef, { denied: Date.now() })
                        // .then(() => console.log('Updated userPermissionRef with denied time'))
                        .catch((error) => console.error('Failed to update denied time:', error));
                    console.log('Push notifications permission denied by the user.');
                    setNotificationPermission(permission.receive);
                }
            } else if (permissionStatus.receive === 'granted') {
                update(userPermissionRef, { grantedCheck: Date.now() })
                await registerDeviceAndListeners();
            } else if (permissionStatus.receive === 'denied') {
                update(userPermissionRef, { deniedCheck: Date.now() })
                setNotificationPermission(permissionStatus.receive);
            }
        };

        const registerDeviceAndListeners = async () => {

            PushNotifications.register();
            PushNotifications.addListener('registration', (token) => {
                console.log('Push registration success, token:', token.value);
                const tokenValue = token.value;
                if (tokenValue) {
                    const userTokenRef = ref(db, `u_tokens/${uid}/${platformKey}`);
                    get(userTokenRef).then((snapshot) => {
                        if (snapshot.exists() && snapshot.val().value === tokenValue) {
                            console.log("Native token is unchanged.");
                        } else {
                            console.log("Native token has changed or is new. Updating...");
                            const tokenData = { value: tokenValue, lastUpdated: Date.now() };
                            update(userTokenRef, tokenData);
                            // console.log('Native token saved to database.');
                        }
                    });
                }

            });

            PushNotifications.addListener('registrationError', (error) => {
                console.error('Error on registration:', error);
            });

            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.log('Push received:', notification);
                const notificationKey = notification.data.notificationId;
                setMsg({
                    title: notification.title,
                    body: notification.body,
                    notificationId: notificationKey || 'test'
                });
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (notificationAction) => {
                // since I don't show alert in capacitor.config.json, this action performed is just for badges
                // console.log('Notification action performed', notificationAction);
                const notificationKey = notificationAction.notification.data.notificationId;
                const notificationsRef = ref(db, `u_notifications/${uid}/${notificationKey}/${platformKey}`);
                // setting msg here, so tapping notification on lock screen and opening the app will show the notification
                // same applies to tapping the drop down notification while some other app is active
                // this is in case on lock screen, the notification preview is not shown
                // without this, tapping notification on lock screen will not show the notification when app is opened
                setMsg({
                    title: notificationAction.notification.title,
                    body: notificationAction.notification.body,
                    notificationId: notificationKey || 'test'
                });
                update(notificationsRef, {
                    tapped: Date.now()
                });

            });


        };


        const registerTokenWeb = async () => {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
            if (token) {
                console.log("Obtained Token: ", token);
                const userTokenRef = ref(db, `u_tokens/${uid}/${platformKey}`);
                get(userTokenRef).then((snapshot) => {
                    if (snapshot.exists() && snapshot.val().value === token) {
                        console.log("Token is unchanged.");
                    } else {
                        console.log("Token has changed or is new. Updating...");
                        update(userTokenRef, { value: token, lastUpdated: Date.now() });
                    }
                });
            }

            return onMessage(messaging, (payload) => {
                console.log('Message received: ', payload);
                setMsg({ title: payload.notification.title, body: payload.notification.body, notificationId: payload.data.notificationId });
            });

        };

        const handleWebNotifications = async () => {
            if (!messaging) return;
            const permission = Notification.permission;

            if (permission === 'default') {
                update(userPermissionRef, { asked: Date.now() })
                    // .then(() => console.log('Updated userPermissionRef with asked time'))
                    .catch((error) => console.error('Failed to update asked time:', error));
                const response = await Notification.requestPermission();

                if (response === "granted") {
                    update(userPermissionRef, { granted: Date.now() })
                        // .then(() => console.log('Updated userPermissionRef with granted time'))
                        .catch((error) => console.error('Failed to update granted time:', error));
                    registerTokenWeb();
                } else {
                    update(userPermissionRef, { denied: Date.now() })
                        // .then(() => console.log('Updated userPermissionRef with denied time'))
                        .catch((error) => console.error('Failed to update denied time:', error));
                    setNotificationPermission('denied');
                    // console.log('Notification permission denied or dismissed.');
                }
            } else if (permission === 'granted') {
                update(userPermissionRef, { grantedCheck: Date.now() });
                setNotificationPermission(permission);
                registerTokenWeb();
            } else {
                // console.log('Notification permission denied or dismissed. Updating the check time...');
                update(userPermissionRef, { deniedCheck: Date.now() });
                setNotificationPermission(permission);
            }


        };


        if (isNative) {
            handleNativeNotifications();
            return () => PushNotifications.removeAllListeners();
        } else {
            handleWebNotifications();
        }

    }, [messaging, db, uid, isNative, platformKey]);


    const onClose = useCallback((notificationKey) => {
        // console.log('closed notification:', notificationKey);
        const notificationsRef = ref(db, `u_notifications/${uid}/${notificationKey}/${platformKey}`);
        update(notificationsRef, {
            closed: Date.now()
        });
        setMsg(null);
    }, [db, uid, platformKey]);


    const onClick = useCallback((notificationKey) => {
        // console.log('clicked notification:', notificationKey);
        const notificationsRef = ref(db, `u_notifications/${uid}/${notificationKey}/${platformKey}`);
        update(notificationsRef, {
            interacted: Date.now()
        });
        setMsg(null);
    }, [db, uid, platformKey]);



    const displayCustomMessage = useCallback((title, description, notificationId, withLink = false) => {

        notiApi.open({
            message: title,
            description: withLink ? <>
                <p>{description}</p>
                <Button type="link" onClick={openSettings}>
                    {platformKey === 'web' ? 'How to Enable Notifications' : 'Turn On Notifications'}
                </Button>
            </> : description,
            icon: (
                <img src="/logo512.png" alt="Custom Icon" style={{ width: '24px', height: '24px' }} />
            ),
            duration: 0,
            onClose: () => onClose(notificationId || 'test'),
            onClick: () => onClick(notificationId || 'test'),
        });

    }, [notiApi, onClose, onClick]);



    useEffect(() => {

        if (msg) {
            // Call the displayCustomMessage function only if msg is not null or empty.
            displayCustomMessage(msg.title, msg.body, msg.notificationId, msg.withLink || false);
        }

        return () => {
            // Clean up listeners when the component unmounts
        };
    }, [msg, displayCustomMessage]);


    return (
        <>
            {contextHolder}
        </>
    );
}

export default App;


