import React, { useState } from 'react';


import {
    Button, Row, Modal
} from 'antd';


import ContactForm from './contact-form';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


export const FormPopup = (props) => {

    return (

        <Modal
            open={props.visible}
            title="Contact Us"
            closable={true}
            maskClosable={false}
            onCancel={props.handleCancel}
            width={440}
            footer={null}
        >
            <div style={{ margin: 10 }}>
                <ContactForm {...props} style={{ margin: "auto" }} />
            </div>
        </Modal>

    );
}



const ContactPage = (props) => {

    const [visible, setVisible] = useState(false);
    const [sent, setSent] = useState(false);

    const onClick = () => {
        hapticsImpactLight();
        setVisible(true);
        setSent(false);
    }

    const handleSuccess = () => {
        setSent(true);
        setVisible(false);
    }

    return (

        <div style={{ maxWidth: 800, margin: "auto", paddingLeft: 24, paddingRight: 24 }}>

            {sent && <Row type="flex" justify="center" style={{ marginTop: 40 }}>
                <h2>Thank you! Your message has been sent.</h2></Row>}

            <Row type="flex" justify="center" style={{ marginTop: 40 }}>

                <Button style={{ minWidth: "50%" }} type="primary" onClick={onClick}>{sent ? 'Write Another Message' : 'Write Message'}</Button>

            </Row>

            <div style={{ marginTop: 40 }}>
                <FormPopup  {...props}
                    visible={visible}
                    handleCancel={() => setVisible(false)}
                    handleSuccess={handleSuccess}
                />
            </div>

        </div>)
}

export default ContactPage;