import React, { useContext } from "react";

import {
    Button,
    Row,
    Typography,
    Popover
} from "antd";

import { FirebaseContext } from '../Firebase';
import { ref, set } from 'firebase/database';

import PricingTable from "./pricingTable";
import RCPricingTable from "./revenueCatPricingTable";

// import { withEmailVerification } from '../Contexts';
import { siteName } from '../../constants';
import { Capacitor } from '@capacitor/core';

const platform = Capacitor.getPlatform();

const { Title, Text } = Typography;

const App = (props) => {


    // const uses = ['Find out how you spend your time',
    //     'guarantee time spent on your most important goal',
    //     'keep procrastination at bay',
    //     'create new habits and stick to routines',
    //     'discover rhythms and patterns in your day',
    //     'record and reference your metrics, thoughts and feelings',
    // ]


    // const uses = ['Find out how you spend your time',
    //     'create new habits and stick to routines',
    //     'keep reference to your metrics, thoughts and mood',
    // ]

    const firebase = useContext(FirebaseContext);
    const { db, auth } = firebase;
    const uid = auth?.currentUser?.uid;

    const onSubscriptionDetailsClick = () => {

        const now = Date.now();
        const usRef = ref(db, 'u_subscription/' + uid + `/detailsclick/${now}`);
        set(usRef, platform);

    };


    const content = (
        <div style={{ maxWidth: 300 }}>
            <p>Subscribing allows uninterrupted access to the app's essential chat function for recording times.</p>
            <p>Choose from our monthly or yearly subscriptions.</p>
            <p>Even without a subscription, you can still enjoy access to all other features.</p>
            <p>Once subscribed, subscription benefits apply on every device where you use {siteName}, even if it's not the device you subscribed from.</p>
            <p>Subscribe today to ensure no disruption in your time recording.</p>
        </div>

    );


    return (<>
        {/* <Flex vertical justify="center" align="center" style={{ marginTop: 40 }}>
            <p>Jobs asks for timesheets, but it's a pain in the a** to make them? </p>
            <p>(Plus don't you need time to do the job, instead of recording what you did?)</p>
            <p>Time just flies, not sure how it's 9pm again?</p>
            <p>So busy, but don't feel like you are accomplishing enough?</p>
            <p>Can't kick your habits of social media, the "free" entertainment that's costing you dearly in your time?</p>
        </Flex> */}
        {/* <Flex vertical justify="center" align="center" style={{ marginTop: 40 }}>
            <Title level={4}>Subscribe to continue using Tellti.me without interruption</Title>
        </Flex> */}


        <Row type="flex" justify="center" style={{ marginTop: 30 }}>
            <Title level={3}>Subscribe to {siteName}</Title>
        </Row>

        <Row type="flex" justify="center">
            <Text type="secondary">Time Told, Moments Held</Text>
        </Row>

        <Row type="flex" justify="center" style={{ padding: 10 }}>
            <Popover content={content} title="" trigger="click" >
                <Button type="link" onClick={onSubscriptionDetailsClick}>What does a subscription offer you?</Button>
            </Popover>
        </Row >

        {platform === "web" && <Row type="flex" justify="center" style={{ marginTop: -10, marginBottom: -10 }}>
            <Title level={4} style={{ color: '#a90506' }}>Limited Time Offer</Title>
        </Row >
        }

        <Row type="flex" justify="center">
            {platform === "web" ? <PricingTable {...props} /> : <RCPricingTable {...props} />}
        </Row>

        {/* <Row type="flex" justify="center">
            <ul style={{ padding: 0, margin: '0 20px 20px' }}>
                {uses.map((feature, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>{feature}</li>
                ))}
            </ul>
        </Row> */}



    </>)


}





export default App;

// export default withEmailVerification(App);


