import React from 'react';

import UnifiedPush from './unifiedPushNotification';
// import WebPush from '../Notifications/webPush';


// import { Capacitor } from '@capacitor/core';


// const platform = Capacitor.getPlatform();

// const App = () => {

//     if (platform === 'ios') {
//         return <IosPush />


//     } else if (platform === 'web') {
//         return <WebPush />
//     }


// };

const App = () => {


    return <UnifiedPush />


};

export default App;

