import React, { useRef, useEffect } from 'react';
import { Input, Button, Form, Flex } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Capacitor } from '@capacitor/core';

const isNative = Capacitor.isNativePlatform();

const { TextArea } = Input;

const ChatModule = ({
    handleSendClick,
    messageQueue,
    // removeMessageFromQueue,
    screenWidth,
    screenHeight,
    isTouch,
    isOnline,
}) => {

    const MAX_QUEUE_SIZE = 3;

    const [form] = Form.useForm();
    // const [fontSize, setFontSize] = useState('small');

    // const fontSizeOptions = [
    //     { label: 'Large Font', value: 'x-large' },
    //     { label: 'Small Font', value: 'small' },
    // ];

    const submitButtonRef = useRef(null); // Ref for the submit button
    const inputRef = useRef(null);

    // const handleFontSizeChange = e => setFontSize(e.target.value);

    // This function is called when the form is submitted
    const onFinish = async (values) => {
        const { message } = values;
        if (message.trim()) { // Ensure message is not empty
            handleSendClick(message.trim());
            form.resetFields();
            // the resetFields may mess with the focus, 
            // so we set the focus after a bit of delay
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    };

    // Function to handle submission via Enter key in TextArea
    const handlePressEnter = (e) => {
        e.preventDefault(); // Prevent the default action to avoid newline
        submitButtonRef.current.click(); // Programmatically click the submit button
    };

    useEffect(() => {
        // on small screens, the soft keyboard may be too clunky and showing up every time the page loads is not ideal
        // so either it doesn't have a soft keyboard or the screen is large enough, we set the focus on the input
        // changed my mind -- the above introduced a bug where the keyboard doesn't close in phone apps -- just use Native to decide
        if (!isNative) {
            inputRef.current?.focus();
        }

    }, [screenHeight, isTouch]);


    return (
        // <Modal
        //     // title={<Radio.Group
        //     //     options={fontSizeOptions}
        //     //     onChange={handleFontSizeChange}
        //     //     value={fontSize}
        //     //     optionType="button"
        //     //     buttonStyle="solid"
        //     // />}
        //     open={isChatModalVisible}
        //     onCancel={() => setIsChatModalVisible(false)}
        //     footer={null}
        //     style={{ opacity: 0.9 }}  // Translucent Modal
        // >
        <>

            {/* {messageQueue?.length > 0 ? <div style={{ marginTop: 30, width: '100%', minHeight: '60px' }}>

                {messageQueue.map((queuedMsg, index) => (
                    <Alert
                        key={index}
                        message={queuedMsg.text?.length <= 45 ? queuedMsg.text : queuedMsg.text?.substring(0, 42) + '...'}
                        showIcon={queuedMsg.status === 'sending'}
                        // description={queuedMsg.text}
                        type="info"
                        closable={queuedMsg.status !== 'sending'}
                        onClose={() => removeMessageFromQueue(queuedMsg.time)}
                        style={{ marginBottom: '10px' }}
                        icon={<LoadingOutlined />}
                    />
                ))}
            </div> : <div style={{ marginTop: 30, width: '90%', minHeight: '60px' }}></div>} */}


            {/* <Radio.Group
                options={fontSizeOptions}
                onChange={handleFontSizeChange}
                value={fontSize}
                optionType="button"
                buttonStyle="solid"
            /> */}


            <Form form={form} onFinish={onFinish}  >

                <Flex size='large' align="flex-end" gap='small'>
                    <Form.Item
                        name="message"
                        style={{ width: '100%', fontSize: "medium" }}
                    >
                        <TextArea
                            ref={inputRef}
                            // className={fontSize === 'small' ? "small-font" : "large-font"}
                            placeholder={isOnline ? (messageQueue.length >= 1 ? `Keep writing. Your messages (up to ${MAX_QUEUE_SIZE}) will be queued...` : "Enter what you wish to record...") : "You are offline. Your messages will be queued..."}
                            showCount
                            maxLength={140}
                            autoSize={{ 'minRows': 3 }}
                            allowClear
                            onPressEnter={handlePressEnter}
                            style={{ backgroundColor: "transparent" }}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                ref={submitButtonRef}
                                htmlType="submit"
                                // loading={messageQueue?.length >= MAX_QUEUE_SIZE}
                                disabled={
                                    !form.getFieldValue('message')?.trim() ||
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                                    messageQueue?.length >= MAX_QUEUE_SIZE
                                }
                                icon={<SendOutlined />}
                            />
                        )}
                    </Form.Item>
                </Flex>
                <Flex style={{ fontSize: 10, marginTop: -20, color: '#777' }} vertical={screenWidth < 400}>
                    <span>The Tellti.me Parrot can make mistakes.&nbsp;</span>
                    <span>Check important info.</span>
                </Flex>
            </Form>
        </>


        // </Modal>
    );
};

export default React.memo(ChatModule);

