import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging } from "firebase/messaging";
import { getAuth, indexedDBLocalPersistence, initializeAuth, browserSessionPersistence } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};




// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = getDatabase(app);

// Get a reference to the messaging service
let messaging;

// Check if running on a native platform with Capacitor
if (Capacitor.isNativePlatform()) {
    // Optionally handle messaging for native platforms
    // Messaging is typically not initiated here because native platforms don't use service workers
    // console.log("Firebase Messaging not initialized on native platforms.");

} else {
    // Ensure messaging is supported on the platform (e.g., not in service worker restricted environments)
    // isSupported().then((supported) => {
    //     if (supported) {
    //         messaging = getMessaging(app);

    //         // Further initialization or handling for web messaging here
    //         // console.log("Firebase Messaging initialized for web platform.");
    //     } else {
    //         // console.log("Firebase Messaging is not supported on this platform.");
    //     }
    // });

    try {
        messaging = getMessaging(app);
    } catch (error) {
        console.error("Messaging not supported:", error);
        messaging = null;
    }
}

// Get a reference to the authentication service

let auth;

if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
    });
} else {
    auth = getAuth(app);
}


export { db, auth, messaging };


// const auth = getAuth(app);

// auth = initializeAuth(app, {
//     persistence: browserSessionPersistence
// });



