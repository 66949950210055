import { useEffect, useContext } from 'react';

import { InAppReview } from '@capacitor-community/in-app-review';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext } from '../Contexts';
import { ref, set } from 'firebase/database';

const RateAppTrigger = () => {

    const firebase = useContext(FirebaseContext);
    const { db } = firebase;
    const { authUser, setAuthUser } = useContext(AuthUserContext);


    useEffect(() => {

        const uid = authUser ? authUser.uid : null;

        if (!InAppReview || !authUser || !uid || !db) {
            return;
        }

        const readyRef = ref(db, `u_settings/${uid}/rateapp`);
        const now = Date.now();

        // if (authUser.rateapp === true || authUser.rateapp < now - 1000 * 60 * 60 * 24 * 90) {  // 90 days

        if (authUser.rateapp === true) {
            InAppReview.requestReview();
            // remove(readyRef);
            setAuthUser({ ...authUser, rateapp: now });
            set(readyRef, now);
        }


    }, [db, authUser, setAuthUser]);


    return null;
}


export default RateAppTrigger;