import { createContext } from 'react';
import { db, auth, messaging } from './config';

// the following can combine with useContext hook to consume FirebaseProvider
export const FirebaseContext = createContext(null);

// the following can be used for class components to consume FirebaseProvider
export const withFirebase = (Component) => (props) => (
    <FirebaseContext.Consumer>
        {(firebase) => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
);

const FirebaseProvider = ({ children }) => {


    const value = {
        db,
        auth,
        messaging,
    };

    return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};


export default FirebaseProvider;


