// import React from 'react';
// import { Modal } from 'antd';
// import { siteName } from '../../constants';


// const WelcomeModal = ({ onBeginTour, modalVisible }) => (


//     <Modal
//         open={modalVisible}
//         onCancel={() => onBeginTour(false)} // or handle differently if you wish to not start the tour immediately
//         onOk={() => onBeginTour(true)}
//         title={`Welcome to ${siteName}!`}
//     >
//         {/* <p>{siteName} is available on your phone as well as on the web. Just use the same sign-in and you'd be all set.</p> */}
//         <p>On this page you can chat with the Parrot. How about a few quick tips to get you started?</p>
//         <p></p>
//     </Modal>
// );

// export default WelcomeModal;


import React, { useState, useEffect, useContext } from 'react';
import { Modal, Checkbox, Input, Button, Flex, Tooltip, Typography } from 'antd';
import { AuthUserContext } from '../Contexts';

import { siteName } from '../../constants';

const { Text } = Typography;


const options = [
    {
        title: "Boost Productivity",
        detail: "I want to optimize my time to achieve more"
    },
    {
        title: "Track Work Hours",
        detail: "I need an easy way to record my work hours for billing or organizational requirements"
    },
    {
        title: "Overcome Procrastination",
        detail: "I'd like to minimize delay and start tasks on time"
    },
    {
        title: "Enhance Habits and Priorities",
        detail: "I aim to cultivate new habits and dedicate more time to what matters"
    },
    {
        title: "Know Myself",
        detail: "I want to discover rhythms and patterns in my day, and explore causes behind them to learn more about myself and my environment"
    },
    {
        title: "Track My Metrics",
        detail: "I want to log and review any metric, from daily status and routines to thoughts and feelings"
    }
];

const WelcomeModal = ({ onBeginTour, modalVisible, handleSaveIntent }) => {



    const [selectedOptions, setSelectedOptions] = useState([]);
    const [otherText, setOtherText] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [screenNumber, setScreenNumber] = useState(0);
    const [firstScreen, setFirstScreen] = useState(0);

    const { authUser } = useContext(AuthUserContext);

    const authUserIntent = authUser?.settings?.intent;

    useEffect(() => {
        if (authUserIntent) {
            setFirstScreen(1);
            setScreenNumber(1);
        }

    }, [authUserIntent, setFirstScreen, setScreenNumber]);


    const handleChange = ({ target: { value, checked } }) => {
        if (checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        }
    };

    const handleOtherCheckboxChange = e => {
        e.target.checked ? setSelectedOptions([...selectedOptions, 'Other']) :
            setSelectedOptions(selectedOptions.filter(option => option !== 'Other'))
    };

    const handleOtherChange = ({ target: { value } }) => {
        setOtherText(value);
    };

    const handleOk = async () => {

        if (screenNumber === 0) {

            // console.log('Selected Options:', selectedOptions);
            // console.log('Other Text:', otherText);
            setSubmitting(true);
            try {
                handleSaveIntent(selectedOptions, otherText.trim());
            } finally {
                setSubmitting(false);
            }


            setScreenNumber(1);
        } else {
            setScreenNumber(firstScreen);
            onBeginTour(true);
        }

    };

    const onModalCancel = () => {

        if (screenNumber === 0) {
            setScreenNumber(1);
            return;
        } else {
            setScreenNumber(firstScreen);
            onBeginTour(false);
        }
    };

    return (
        <Modal
            open={modalVisible}
            onCancel={onModalCancel}
            onOk={handleOk}
            title={screenNumber === 0 ? `Welcome to ${siteName}!` : "Quick tips?"}
            footer={[<Button key="back" onClick={onModalCancel}>
                Not Now
            </Button>,
            <Button key="submit" type="primary" loading={submitting} onClick={handleOk}>
                {screenNumber === 0 ? 'Submit' : 'Show Tips'}
            </Button>
            ]}
        >
            {screenNumber === 0 && <div>

                <p>{`What are your main goals for using ${siteName}? - So that we can tailor your experience:`}</p>
                <Flex vertical gap="small">
                    {options.map(option => (
                        <Checkbox key={option.title} value={option.title} onChange={handleChange} checked={selectedOptions.includes(option.title)}>
                            <Tooltip title={option.detail}>{selectedOptions.includes(option.title) ?
                                <><Text strong>{option.title}&nbsp;</Text> <Text>{' (' + option.detail + ')'}</Text></> :
                                <> <Text strong>{option.title + '...'}</Text></>}</Tooltip>
                        </Checkbox>
                    ))}
                    <Checkbox
                        checked={selectedOptions.includes('Other')}
                        onChange={handleOtherCheckboxChange}
                    >
                        <Tooltip title="Please specify, details welcome"><Text strong>Other:</Text></Tooltip>
                    </Checkbox>
                </Flex>
                {selectedOptions.includes('Other') && (
                    <Input placeholder="Please specify, details welcome" value={otherText} onChange={handleOtherChange} style={{ marginTop: 8 }} />
                )}
            </div>
            }

            {
                screenNumber === 1 && <p>On this page you can chat with the Parrot. How about a few quick tips to get you started?</p>
            }
        </Modal >
    );
};

export default WelcomeModal;


