import React, { useEffect, useState, useContext } from 'react';
import { Capacitor } from '@capacitor/core';
import { FirebaseContext } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Contexts';
import { Purchases } from '@revenuecat/purchases-capacitor';

import {
    Button
} from 'antd';
import { ref, set } from 'firebase/database';

// note this checkout button by default is not embedded, but does a redirect
const CheckoutButton = ({ level, offerings, email, buttonText = 'Checkout', isEmbedded = false, promptLogin, ...restProps }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [messageIndex, setMessageIndex] = useState(0);

    const firebase = useContext(FirebaseContext);
    const user = firebase.auth.currentUser;
    const uid = user?.uid;
    const db = firebase.db;

    const messages = [buttonText, "Hold on...", "Just a moment...", "Finishing up..."];

    const { authUser, setAuthUser } = useContext(AuthUserContext);

    const navigate = useNavigate();

    useEffect(() => {

        let interval;
        // Only setup interval if loading is true
        if (loading) {
            interval = setInterval(() => {
                setMessageIndex(prevIndex => {
                    // Check if the next index would exceed the bounds of the messages array
                    if (prevIndex >= messages.length - 1) {
                        clearInterval(interval); // Clear interval if it's the last message
                        return prevIndex; // Keep the index at the last message
                    } else {
                        return prevIndex + 1; // Increment the message index
                    }
                });
            }, 2000);
        }
        else {
            // Reset messageIndex to zero when loading is false
            setMessageIndex(0);
        }

        // Cleanup function to clear the interval
        return () => {
            if (interval) {
                clearInterval(interval);
                setMessageIndex(0); // Reset the message index when the interval is cleared
            }
        };

    }, [loading]); // Only re-run the effect when `loading` changes


    const handleCheckout = async () => {

        console.log('level is', level);


        if (!level) {
            console.error('Please select a subscription level.');
            return;
        }

        if (!user.uid) {
            promptLogin();
            return;
        }

        setLoading(true);
        setError('');
        setMessageIndex(0);

        const identifier = level === 'monthly' ? '$rc_monthly' : '$rc_annual';

        // Set or log in the user with RevenueCat
        try {
            Purchases.logIn({ appUserID: user.uid });
            console.log("User logged in with RevenueCat with ID: ", user.uid);
        } catch (error) {
            console.error('Error setting appUserID in RevenueCat:', error);
            setError('Error initializing user data. Please try again.');
            setLoading(false);
            return;
        }

        try {
            // record checkout intent in db
            const now = Date.now();
            const usRef = ref(db, 'u_subscription/' + uid + `/checkoutclick/${now}`);
            const platform = Capacitor.getPlatform() || ''; // returns 'ios', 'android', or 'web'
            set(usRef, platform);

            const selectedPackage = offerings.availablePackages.find(pkg => pkg.identifier === identifier);
            console.log('Selected package:', selectedPackage);

            if (selectedPackage) {
                const purchaseResult = await Purchases.purchasePackage({ aPackage: selectedPackage });
                console.log("Customer Info: ", purchaseResult.customerInfo);

                if (typeof purchaseResult.customerInfo.entitlements.active["all_access"] !== "undefined") {
                    console.log("Purchase successful and entitlement is active");

                    console.log('expiration dates:', purchaseResult.customerInfo.allExpirationDates);

                    const expirationTimestampMs = purchaseResult.customerInfo.allExpirationDates['all_access'];

                    // Update the user context
                    setAuthUser({
                        ...authUser,
                        level: level,
                        payment_provider: 'revenuecat',
                        current_period_end: expirationTimestampMs ? Math.floor(expirationTimestampMs / 1000) : null, // Assuming you get expiration date as a timestamp
                        rateapp: true,
                    });


                    const timer = setTimeout(() => {
                        navigate(ROUTES.PAYMENTSUCCESS);
                    }, 100);

                    // Cleanup function in useEffect to clear timeout if component unmounts
                    return () => clearTimeout(timer);

                } else {
                    console.error("Purchase unsuccessful or entitlement not granted");
                }
            }
        } catch (error) {
            console.error('Error during checkout:', error);
            if (error.code === 'PURCHASE_CANCELLED_ERROR') {
                // Handle the case where the user cancels the purchase
            } else {
                // Handle other errors
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Button key={level} {...restProps} onClick={handleCheckout} loading={loading} disabled={loading || !level}>
                {loading ? messages[messageIndex] : buttonText}
            </Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default CheckoutButton;

