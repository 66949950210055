import React, { useState, useEffect, useRef, useCallback, useContext } from "react";

import {
    Form, Input, Button, message
} from 'antd';


import ReCAPTCHA from "react-google-recaptcha";


import { submitWebform } from '../../functions';

import { AuthUserContext } from '../Contexts';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};




const { TextArea } = Input;

const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const messages = ['Send', "Hold on...", "Just a moment...", "Finishing up..."];

const App = (props) => {

    const [loading, setLoading] = useState(false);
    // const [captchaToken, setCaptchaToken] = useState(null);
    const [messageIndex, setMessageIndex] = useState(0);

    const [form] = Form.useForm();


    // const firebase = useContext(FirebaseContext);
    const { authUser } = useContext(AuthUserContext);

    const [messageApi, contextHolder] = message.useMessage();


    const recaptchaRef = useRef();

    // Executes reCAPTCHA when the form is submitted
    const executeRecaptcha = async () => {
        try {
            const token = await recaptchaRef.current.executeAsync();
            return token;
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            throw error; // Propagate the error so that it can be handled in the calling function
        }
    };



    const resetState = useCallback(() => {
        setLoading(false);
        // setCaptchaToken(null);
        // Reset the reCaptcha

        recaptchaRef.current?.reset();
    }, [recaptchaRef]);



    // const submitReCAPTCHA = async () => {
    //     try {
    //         const token = await recaptchaRef.current.executeAsync();
    //         setCaptchaToken(token);
    //         return token;
    //     } catch (error) {
    //         console.error('Error executing reCAPTCHA:', error);
    //         setCaptchaToken(null);
    //         throw error; // Propagate the error so that it can be handled in the calling function
    //     }
    // };


    const handleSuccess = () => {
        form.resetFields();
        // console.log("reset fields");
        props.handleSuccess();
        resetState();
        setLoading(false);
        messageApi.open({
            type: 'success',
            content: 'Message sent',
            duration: 3,
        });
    };




    const onFinish = async (values) => {

        hapticsImpactLight();
        setLoading(true);

        try {
            // let recaptchaToken = captchaToken ? captchaToken : await submitReCAPTCHA();
            const recaptchaToken = await executeRecaptcha();

            if (!recaptchaToken) {
                throw new Error("Failed to obtain ReCAPTCHA token");
            }

            const submissionData = {
                recaptchaToken,
                formdata: { ...values, uid: (authUser && authUser.uid) || null }
            };


            await submitWebform(submissionData);
            handleSuccess();



            // here I temporarily use handleCancel instead of the dedicated handleFinish
            // handleCancel();

        } catch (error) {
            console.error('Error during form submission:', error);
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Message not sent. Please try again.',
                duration: 3,
            });

        }


    }


    useEffect(() => {

        let interval;
        // Only setup interval if loading is true
        if (loading) {
            interval = setInterval(() => {
                setMessageIndex(prevIndex => {
                    // Check if the next index would exceed the bounds of the messages array
                    if (prevIndex >= messages.length - 1) {
                        clearInterval(interval); // Clear interval if it's the last message
                        return prevIndex; // Keep the index at the last message
                    } else {
                        return prevIndex + 1; // Increment the message index
                    }
                });
            }, 2000);
        }
        else {
            // Reset messageIndex to zero when loading is false
            setMessageIndex(0);
        }

        // Cleanup function to clear the interval
        return () => {
            if (interval) {
                clearInterval(interval);
                setMessageIndex(0); // Reset the message index when the interval is cleared
            }
        };

    }, [loading]); // Only re-run the effect when `loading` changes




    return (
        <>{contextHolder}
            <Form onFinish={onFinish}
                name="contactForm"
                className="form-container"
                layout="vertical"
                form={form}
                initialValues={{ email: authUser && authUser.email }}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{
                        required: true,
                        message: 'required',
                    }]}
                    validateTrigger={['onBlur']}
                    hasFeedback
                >
                    <Input allowClear placeholder="Your name" autoComplete="on" />
                </Form.Item>

                <Form.Item
                    // style={{ marginTop: 30 }}
                    name="email"
                    label="Email"
                    rules={[{ type: 'email', message: 'Not a valid email address' },
                    { required: true, message: 'required' }]}
                    validateTrigger={['onBlur']}
                    hasFeedback
                >
                    <Input allowClear placeholder="Email address" autoComplete="on" />
                </Form.Item>

                <Form.Item
                    // {...formItemLayout}
                    label="Subject"
                    name="subject"
                    labelAlign="left"
                    rules={[
                        {
                            required: true,
                            message: 'Email subject is required',
                        },
                        {
                            max: 100,
                            message: 'No more than 100 characters.'
                        }
                    ]}
                >
                    <Input allowClear maxLength={100} />

                </Form.Item>

                <Form.Item
                    label="Message"
                    name="bodytext"
                    labelAlign="left"
                    rules={[
                        {
                            required: true,
                            message: 'Message is required',
                        },
                        {
                            max: 300,
                            message: 'No more than 300 characters.'
                        }
                    ]}
                >
                    <TextArea autoSize={{ minRows: 5 }} showCount allowClear maxLength={300} style={{ minHeight: 100 }} />

                </Form.Item>


                {props.visible ?
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={sitekey}
                    />
                    : <span></span>
                }

                <Form.Item
                >
                    <Button className="login-form-button" type="primary" htmlType="submit" loading={loading}>{loading ? messages[messageIndex] : "Send"}</Button>
                </Form.Item>


            </Form>




        </>
    )
}

export default App;
