import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';


import { FirebaseContext } from '../Firebase';
import { ref, update, remove } from 'firebase/database';
import moment from 'moment-timezone';
import ArcGraph from './arc-duration';

import { fetchDataAndProcess } from './utils';

import { Space, InputNumber, Button, Layout, Alert } from 'antd';

import { CaretLeftOutlined, CaretRightOutlined, MenuOutlined, BulbOutlined } from '@ant-design/icons';
import { useAuthorization, withEmailVerification, useScreen, AuthUserContext, DateContext, useNetwork } from '../Contexts';

import dayjs from 'dayjs';

import DatePickerWithDots from './date-picker';
import HourInput from './hourInput';
import { LayoutWithNav } from '../Navigation';
import { CustomTour } from './customTour';
import { siteName } from '../../constants';
import FloatMenu from '../Chat/floatMenu';
// import useGraphMode from './useGraphMode';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


const { Content, Footer } = Layout;

const App = () => {

    // console.log('span page loaded');

    const authCondition = useCallback(authUser => !!authUser, []);
    useAuthorization(authCondition);

    const { date, setDate } = useContext(DateContext);

    // const [editMode, setEditMode] = useState(false);

    const { isOnline } = useNetwork();



    const dateText = date ?? moment().format('YYYY-MM-DD');

    const { authUser, setAuthUser } = useContext(AuthUserContext);


    const targetComponentRef = useRef(null);

    const { width: screenWidth, height: screenHeight, isTouch } = useScreen();

    const [graphData, setGraphData] = useState(null);

    // const [dateText, setDateText] = useState(moment().format('YYYY-MM-DD'));
    // const [mode, setMode] = useState('regular');
    const [prior, setPrior] = useState(authUser?.settings?.prior ?? 3);
    const [post, setPost] = useState(authUser?.settings?.post ?? 3);
    const [tourOpen, setTourOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    const firebase = useContext(FirebaseContext);

    const user = firebase.auth.currentUser;
    const uid = user?.uid;
    const { db } = firebase;

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const onCloseTour = useCallback(() => {
        setTourOpen(false);
    }, []);

    useEffect(() => {

        if (authUser && authUser.settings?.span_tour_seen == null) {
            setShowAlert(true);
        }

    }, [authUser]);

    const onFinishTour = useCallback(() => {
        if (authUser && !authUser.settings?.span_tour_seen) {
            setAuthUser({ ...authUser, settings: { ...authUser.settings, span_tour_seen: true } });
            const userRef = ref(db, `u_settings/${uid}`);
            update(userRef, { span_tour_seen: true }).catch((error) => { console.error(error) });
        }
    }, [authUser, setAuthUser]);


    // const handleModeChange = e => {
    //     setMode(e.target.value);  // Update mode based on radio button selection
    // };

    const onChange = (date, dateString) => {
        hapticsImpactLight();
        if (dateString) {
            // setDateText(dateString);
            setDate(dateString);
        }
    };

    const onClickDayChange = (direction) => {
        hapticsImpactLight();
        const newDay = direction === 'back' ? moment(dateText).subtract(1, 'day').format('YYYY-MM-DD') : moment(dateText).add(1, 'day').format('YYYY-MM-DD');
        // setDateText(newDay);
        setDate(newDay);
    };


    const onAdjustChange = async (value, type) => {
        // Ensure that the value is an integer. If not, revert to the previous value.

        if (isNaN(value) || value === null || value < 0 || value > 9) {
            // console.log('Value is not a number:', value);
            return;
        }

        const intValue = Math.floor(value);

        if (type === 'prior') {
            // console.log('setting prior to', intValue);
            setPrior(intValue);
            setAuthUser({ ...authUser, settings: { ...authUser.settings, prior: intValue } });
        } else if (type === 'post') {
            // console.log('setting post to', intValue);
            setPost(intValue);
            setAuthUser({ ...authUser, settings: { ...authUser.settings, post: intValue } });
        }

        // Save settings to the database
        const settingsRef = ref(db, `u_settings/${uid}`);
        await update(settingsRef, { [type]: intValue });
    };


    const createEdge = uid => async (sourceRecordId, targetRecordId, adjust) => {
        // Check if sourceRecordId and targetRecordId are the same
        if (sourceRecordId === targetRecordId) {
            // Handle the case to remove "split_span" as it's a self-referential edge
            const databaseRef = ref(db, `u_edits/${uid}/${sourceRecordId}/split_span`);
            try {
                // Remove the "split_span" from the database
                await remove(databaseRef);
                console.log(`"split_span" removed for record ${sourceRecordId}`);
            } catch (error) {
                console.error('Error removing "split_span":', error);
            }
        } else {
            // Handle the normal case to create the edge
            const databaseRef = ref(db, `u_edits/${uid}`);
            const dataToUpdate = {
                [`${sourceRecordId}/span/${targetRecordId}/${adjust}`]: true,
                [`${targetRecordId}/of_span/${sourceRecordId}`]: true
            };

            try {
                await update(databaseRef, dataToUpdate);
                // console.log(`Edge created between ${sourceRecordId} and ${targetRecordId} with adjust ${adjust}`);
            } catch (error) {
                console.error('Error creating edge:', error);
            }
        }
    };


    const removeEdge = uid => async (sourceRecordId, targetRecordId, adjust) => {
        // Check if sourceRecordId and targetRecordId are the same
        if (sourceRecordId === targetRecordId) {
            // Handle the case to add "split_span" as true
            const databaseRef = ref(db, `u_edits/${uid}/${sourceRecordId}`);
            const dataToUpdate = {
                "split_span": true
            };

            try {
                await update(databaseRef, dataToUpdate);
                console.log(`"split_span" set to true for record ${sourceRecordId}`);
            } catch (error) {
                console.error('Error setting "split_span" to true:', error);
            }
        } else {


            // Define the reference to the specific user's edits directory
            const databaseRef = ref(db, `u_edits/${uid}`);

            // Define the paths to delete by setting them to null
            const dataToRemove = {
                [`${sourceRecordId}/span/${targetRecordId}/${adjust}`]: null,  // Set to null to indicate deletion
                [`${targetRecordId}/of_span/${sourceRecordId}`]: null          // Set to null to indicate deletion
            };

            // Perform the batched update to remove the data
            try {
                await update(databaseRef, dataToRemove);
                // console.log(`Edge removed between ${sourceRecordId} and ${targetRecordId} with adjust ${adjust}`);
            } catch (error) {
                console.error('Error removing edge:', error);
            }

            // // Handle the case to remove the edge
            // const sourceRef = ref(db, `u_edits/${uid}/${sourceRecordId}/span/${targetRecordId}/${adjust}`);
            // const targetRef = ref(db, `u_edits/${uid}/${targetRecordId}/of_span/${sourceRecordId}`);

            // try {
            //     await remove(sourceRef); // Removes the data at this database reference.
            //     await remove(targetRef); // Removes the data at this database reference.
            //     // console.log(`Edge removed between ${sourceRecordId} and ${targetRecordId} with adjust ${adjust}`);
            // } catch (error) {
            //     console.error('Error removing edge:', error);
            // }
        }
    };

    // const updateNodeData = uid => async (recordId, dataToUpdate) => {
    //     const databaseRef = ref(db, `u_edits/${uid}/${recordId}`);
    //     try {
    //         await update(databaseRef, dataToUpdate);
    //         console.log(`Node data updated for record ${recordId}`);
    //     } catch (error) {
    //         console.error('Error updating node data:', error);
    //     }
    // }

    const updateEdgeData = uid => async (sourceRecordId, targetRecordId, adjust, dataToUpdate) => {

        // console.log('sourceRecordId', sourceRecordId, 'targetRecordId', targetRecordId, 'adjust', adjust, 'dataToUpdate', dataToUpdate);

        if (sourceRecordId !== targetRecordId) {
            const databaseRef = ref(db, `u_edits/${uid}/${sourceRecordId}/span/${targetRecordId}/${adjust}`);
            try {
                await update(databaseRef, dataToUpdate);
                // console.log(`Edge data updated between ${sourceRecordId} and ${targetRecordId} with adjust ${adjust}`);
            } catch (error) {
                console.error('Error updating edge data:', error);
            }
        } else {
            // console.log('same source and targetRecord', 'adjust is', adjust, 'dataToUpdate is', dataToUpdate);
            const databaseRef = ref(db, `u_edits/${uid}/${sourceRecordId}`);
            try {
                await update(databaseRef, dataToUpdate);
                // console.log(`Data updated for record ${sourceRecordId}`);
            } catch (error) {
                console.error('Error updating edge data:', error);
            }
        }
    };




    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchDataAndProcess(uid, dateText, userTimezone, db, prior, post);
                setGraphData(data);
            } catch (error) {
                console.error('Error fetching and processing data:', error);
                setGraphData(null); // reset graph data on error
            }
        };

        if (uid && db && dateText && prior !== null && post !== null) {
            fetchData();
        }


    }, [uid, dateText, userTimezone, db, prior, post, setGraphData]);


    // useEffect(() => {

    //     if (!authUser?.settings?.span_tour_seen && graphData?.nodes?.length > 0) {
    //         setTourOpen(true);
    //     }

    // }, [authUser, graphData, setTourOpen]);

    // // Use the custom hook
    // const graphData = useGraphData(user?.uid, dateText, userTimezone, db);



    // Custom hook to fetch graph data
    // useGraphData(user?.uid, dateText, userTimezone, db, prior, post, setGraphData);

    // Function to update graph data directly
    const updateGraphData = (nodeOrEdge, id, updatedData) => {
        // Logic to update graphData based on updatedData
        // This could involve merging updatedData into the existing graphData
        // or replacing parts of it

        if (nodeOrEdge === 'node') {
            setGraphData(currentData => {
                // Example: Update nodes or edges as needed
                // This is a simplistic example; your actual update logic might be more complex
                return {
                    ...currentData,
                    nodes: currentData.nodes.map(node =>
                        node.id === id ? { ...node, ...updatedData } : node
                    ),
                };
            });

        } else if (nodeOrEdge === 'edge' && id.length === 2) {

            setGraphData(currentData => {
                // Example: Update nodes or edges as needed
                // This is a simplistic example; your actual update logic might be more complex
                return updatedData === null ? {
                    ...currentData,
                    edges: currentData.edges.filter(edge =>
                        !(edge.source === id[0] && edge.target === id[1])
                    ),
                } : {
                    ...currentData,
                    edges: [...currentData.edges, {
                        ...updatedData,
                        source: id[0],
                        target: id[1],
                    }]
                };
            });

        }

    };


    // // Function to determine the right margin based on the window width
    // function getRightDistance() {
    //     // Example: if the screen width is greater than 1200px, set right distance to 200px,
    //     // otherwise set it to 10% of the screen width
    //     if (screenWidth < 400) {
    //         return screenWidth * 0.05; // Fixed pixels for very wide screens
    //     } else {
    //         return screenWidth * 0.15; // 10% of the screen width for others
    //     }
    // }

    // const toggleGraphMode = () => {
    //     setEditMode(!editMode);
    // };


    return (
        <LayoutWithNav>

            <Layout style={{ flexGrow: 1 }}>
                <Content>
                    {/* {graphData && graphData.nodes?.length > 0 && <FloatButton
                        type={tourOpen ? "default" : "primary"}
                        icon={<BulbOutlined />}
                        onClick={() => setTourOpen(true)}
                        tooltip={"Show Tips"}
                        style={{ right: getRightDistance(screenWidth), top: screenWidth < 300 ? 60 : 30 }}
                    />} */}

                    {/* {graphData && graphData.nodes?.length > 0 &&  */}

                    <FloatMenu
                        top={50}
                        screenWidth={screenWidth}
                        screenHeight={screenHeight}
                        tourOpen={tourOpen}
                        setTourOpen={setTourOpen}
                    />



                    <div style={{ padding: screenWidth < 300 ? '12px 8px' : '12px' }} >
                        <div style={{ display: "inline-block", margin: "46px 0px 20px 20px" }} ref={targetComponentRef}>
                            <Space.Compact >
                                {/* <InputNumber
                                    className="span-arc-input-number"
                                    min={0}
                                    max={9}
                                    style={{ width: 30 }}
                                    controls={false}
                                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // parser={value => {
                                    //     const match = value.replace(/[^\d]/g, '').match(/\d/);
                                    //     console.log('Parsing value:', value, 'to', match);
                                    //     return match ? match[0] : '';
                                    // }}
                                    parser={value => value.replace(/[^\d]/g, '')[0] || ''}
                                    onChange={value => onAdjustChange(value, 'prior')}
                                    value={prior}
                                    // suffix={<span style={{ marginLeft: 26, paddingLeft: 26 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h</span>}
                                    suffix='h'
                                /> */}
                                <HourInput
                                    value={prior}
                                    onChange={(value) => onAdjustChange(value, 'prior')}
                                />
                                <Button style={{ maxWidth: 24 }} onClick={() => onClickDayChange('back')} icon={<CaretLeftOutlined />}></Button>
                                <DatePickerWithDots onChange={onChange} value={dayjs(dateText)} style={{ maxWidth: 130 }} />
                                <Button style={{ maxWidth: 24 }} onClick={() => onClickDayChange('forward')} icon={<CaretRightOutlined />}></Button>
                                {/* <InputNumber
                                    className="span-arc-input-number"
                                    min={0}
                                    max={9}
                                    style={{ width: 30 }}
                                    controls={false}
                                    parser={value => value.replace(/[^\d]/g, '')[0] || ''}
                                    onChange={value => onAdjustChange(value, 'post')}
                                    value={post}
                                    suffix='h'
                                /> */}
                                <HourInput
                                    value={post}
                                    onChange={(value) => onAdjustChange(value, 'post')}
                                />
                                {/* <Button
                                    type={editMode ? "default" : "primary"}
                                    style={{ maxWidth: 30 }}
                                    onClick={toggleGraphMode}
                                    icon={<DragOutlined />} /> */}


                            </Space.Compact>
                        </div>

                        {/* <div style={{ margin: "16px 40px" }}>
                            <Switch
                                checkedChildren={<EditOutlined />}  // Icon for light mode
                                unCheckedChildren={<RollbackOutlined style={{ transform: 'rotate(180deg) scaleX(-1)' }} />}  // Icon for dark mode
                                checked={modeRef.current}  // The switch state is 'checked' when in light mode
                                onChange={setMode}  // Toggle function changes theme mode
                                // , ...props.style
                                style={{ background: '#00A9A8', transform: 'scale(1.3)' }}
                            />
                        </div> */}


                        {!isOnline && <Alert
                            type="warning"
                            message="You are offline"
                            description="Changes made will not be saved"
                            style={{ marginTop: '20px' }}
                            closable
                        />}

                        {showAlert && <Alert
                            message="How about some tips for using this page?"
                            description={<>Press <BulbOutlined /> from the <MenuOutlined /> menu to see them anytime.</>}
                            type="info"
                            action={
                                <Space direction="vertical">
                                    <Button size="small" type="primary" onClick={() => { setTourOpen(true); setShowAlert(false) }} style={{ width: 80 }}>
                                        Show Me
                                    </Button>
                                    <Button size="small" onClick={() => setShowAlert(false)} style={{ width: 80 }}>
                                        Not Now
                                    </Button>
                                </Space>
                            }
                            style={{ marginTop: '20px' }}
                            closable
                        />}




                        {user?.uid && (graphData?.nodes?.length > 0 ? <ArcGraph
                            userData={graphData}
                            dateText={dateText}
                            createEdge={createEdge(user.uid)}
                            removeEdge={removeEdge(user.uid)}
                            // updateNodeData={updateNodeData(user.uid)}
                            updateEdgeData={updateEdgeData(user.uid)}
                            updateGraphData={updateGraphData}
                            isTouch={isTouch}
                        /> : <div style={{ textAlign: 'center', marginTop: 80 }}>{isOnline ? 'No data available for this date' : 'No internet connection at this moment'}</div>)}

                        <CustomTour
                            open={tourOpen}
                            onCloseTour={onCloseTour}
                            onFinishTour={onFinishTour}
                            screenWidth={screenWidth}
                            isTouch={isTouch}
                            ref1={targetComponentRef}
                        />

                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', padding: '2px 14px 14px 14px', fontSize: "14px" }}>©{new Date().getFullYear()} {siteName} created by Spearmint Labs</Footer>
            </Layout>
        </LayoutWithNav >)
};





// // Custom hook for fetching and processing graph data
// const useGraphData = (uid, date, timezone, db) => {
//     // State for storing graph data
//     const [graphData, setGraphData] = useState({});

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const data = await fetchDataAndProcess(uid, date, timezone, db);
//                 setGraphData(data);
//             } catch (error) {
//                 console.error('Error fetching and processing data:', error);
//                 setGraphData({}); // reset graph data on error
//             }
//         };

//         if (uid) {
//             fetchData();
//         }
//     }, [uid, date, timezone, db]); // Only re-run the effect if uid, date, or timezone changes

//     return graphData;
// };


// const useGraphData = (uid, date, timezone, db, prior, post, setGraphData) => {


// };


export { DatePickerWithDots };


export default withEmailVerification(App);