import React, { useContext, useEffect, useState } from "react";

import {
    Layout, Row, Button, Typography, Alert
} from "antd";

import moment from 'moment';
import { ref, get } from 'firebase/database';

import LandingFooter from '../Landing/footer';


import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useNetwork } from '../Contexts';


import { signOut } from "firebase/auth";

import LoginCred from './logincred';
import ToSubscribe from './toSubscribe';
import MySubscription from './mySubscription';
import DeleteAccount from './deleteAccount';




const { Title } = Typography;

const { Content, Footer } = Layout;



const App = (props) => {

    const { isOnline } = useNetwork();
    const firebase = useContext(FirebaseContext);
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const { db, auth } = firebase;
    const uid = auth?.currentUser?.uid;
    const [active, setActive] = useState(false);


    const SubscriptionComponent = active ? MySubscription : ToSubscribe;

    const handleSignout = () => {

        localStorage.setItem('pendingMessages', null);
        signOut(firebase.auth);
    }


    useEffect(() => {

        const fetchUserDetails = async () => {
            if (uid) {
                const userRef = ref(db, 'users/' + uid);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setAuthUser({ ...authUser, ...userData });
                }
            }
        };

        fetchUserDetails();



    }, [db, uid]); // deliberately omitting authUser to avoid infinite loop


    useEffect(() => {

        // current_period_end is in seconds
        const timeNow = moment().unix();
        setActive(authUser?.current_period_end && authUser.current_period_end > timeNow);

        // authUser gets updated in the useEffect above, upon page load
        // it also gets updated in the subscription component

    }, [authUser?.current_period_end])





    return (


        <Layout
            style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: '100vh', // minHeight here only to fill the viewport initially

            }}
        >

            <Content>
                <div style={{ padding: "24px 16px 10px 16px", margin: "auto" }}>


                    {/* <CheckoutButton email={authUser?.email} level='monthly' /> */}

                    <Row type="flex" justify="start" style={{ margin: "20px 40px 0px 40px" }}>
                        <Title level={4}>{authUser?.email}</Title>

                    </Row>

                    {!isOnline && <Alert
                        type="warning"
                        message="You are offline"
                        description="Changes made will not be saved"
                        style={{ marginTop: '20px' }}
                        closable
                    />}


                    <Row type="flex" justify="center" style={{ margin: "10px 10px" }}>
                        <LoginCred {...props} />
                        <Button onClick={handleSignout}>Sign Out</Button>
                    </Row>



                    <div style={{ marginBottom: 20 }}>
                        <SubscriptionComponent authUser={authUser} />
                    </div>

                    <div style={{ marginBottom: 40 }}>
                        <DeleteAccount authUser={authUser} />
                    </div>

                </div>
            </Content>

            <Footer
                style={{
                    bottom: 0,
                    padding: 0,
                }}
            >
                <LandingFooter />
            </Footer>


        </Layout>
    );
};


export default App;