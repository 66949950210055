import axios from 'axios';

// import { makeCancelable } from '../Hooks';
// import { Capacitor } from '@capacitor/core';


const produ = 'https://us-central1-timekeeper-efa11.cloudfunctions.net/';

const testu = 'http://localhost:5001/timekeeper-efa11/us-central1/';


const USER_CREATION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'write_user'
    : testu + 'write_user';


const CHAT_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/answer_chat'
    : testu + 'api/answer_chat';

const CHECKOUT_SERVER_URL = process.env.NODE_ENV === 'production'
    ? produ + 'create_checkout_session'
    : testu + 'create_checkout_session';

const CHECKOUT_SERVER_WITH_REDIRECT_URL = process.env.NODE_ENV === 'production'
    ? produ + 'create_checkout_session_with_redirect'
    : testu + 'create_checkout_session_with_redirect';


const WEBFORM_SUBMISSION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/webform'
    : testu + 'api/webform';


const CUS_PORTAL_WITH_INTEGRATION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'portal_with_integration'
    : testu + 'portal_with_integration';


const ACCOUNT_DELETION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/delete_account'
    : testu + 'api/delete_account';


// const CREATE_SPAN_URL = process.env.NODE_ENV === 'production'
//     ? produ + 'create_span'
//     : testu + 'create_span';

export const createUser = (referrer, email, platform, recaptchaToken, firebaseToken) => {

    const headers = {
        Authorization: 'Bearer ' + firebaseToken,
    };

    return axios.post(USER_CREATION_URL,
        {
            referrer,
            email,
            recaptchaResponse: recaptchaToken,
            platform,
        }, { headers: headers });
};

export const sendChat = (message, timezone, firebaseToken, utcTimestamp, platform, resend = false) => {

    const headers = {
        Authorization: 'Bearer ' + firebaseToken,
    };

    return axios.post(CHAT_URL,
        { message, timezone, utcTimestamp, resend, platform },
        { headers: headers });
}



export const createCheckoutSession = async (email, level, platform, firebaseToken, redirect = false) => {

    const headers = {
        Authorization: 'Bearer ' + firebaseToken,
    };

    const cloudFunctionUrl = redirect ? CHECKOUT_SERVER_WITH_REDIRECT_URL : CHECKOUT_SERVER_URL;

    // console.log('server url is', cloudFunctionUrl, 'and', email, level);

    return axios.post(cloudFunctionUrl,
        { email, level, platform },
        {
            headers: headers,
            validateStatus: function (status) {
                // Accept all status codes
                return true; // This tells Axios to resolve the promise for all HTTP status codes
            }
        });

};


// export const createSpan = (sourceRecordId, targetRecordId, adjust, firebaseToken) => {
//     const headers = {
//         Authorization: 'Bearer ' + firebaseToken,
//     };

//     // Define the payload for your cloud function
//     const data = {
//         sourceRecordId,
//         targetRecordId,
//         adjust
//     };

//     // Call the cloud function URL
//     return axios.post(CREATE_SPAN_URL, data, { headers });
// }




// export const createStripeCus = async (firebase, email, uid, success, error) => {

//     console.log(firebase);

//     const token = await firebase.auth.currentUser.getIdToken();

//     const headers = {
//         Authorization: 'Bearer ' + token,
//     };

//     const wrappedPromise = makeCancelable(axios.post(CUS_CREATION_SERVER_URL, { email, uid }, { headers: headers }));

//     wrappedPromise.promise.then(success)
//         .catch(error);

// }



export const submitWebform = async (formdata, quick = false) => {

    return axios.post(WEBFORM_SUBMISSION_URL,
        { ...formdata, quick });


}



export const createPortalSession = async (uid, firebaseToken) => {

    const headers = {
        Authorization: 'Bearer ' + firebaseToken,
    };

    const cloudFunctionUrl = CUS_PORTAL_WITH_INTEGRATION_URL

    // console.log('server url is', cloudFunctionUrl,);

    return axios.post(cloudFunctionUrl,
        { uid },
        {
            headers: headers,
            validateStatus: function (status) {
                // Accept all status codes
                return true; // This tells Axios to resolve the promise for all HTTP status codes
            }
        });

};


export const deleteAccount = async (firebaseToken) => {

    const headers = {
        Authorization: 'Bearer ' + firebaseToken,
    };

    return axios.post(ACCOUNT_DELETION_URL,
        {},
        { headers: headers });

}