import React, { useState, useContext, useEffect } from 'react';
import { Card, Radio, Typography } from 'antd';
// import { siteName } from '../../constants';

import CheckoutButtonNative from '../CheckOut/checkout_button_native';

import { AuthUserContext } from '../Contexts';
// import { Capacitor } from '@capacitor/core';

import { Purchases } from '@revenuecat/purchases-capacitor';
// import { off } from 'hammerjs';

const { Text, Title } = Typography;


const PricingTable = (props) => {

    const [plan, setPlan] = useState('yearly');
    const [offerings, setOfferings] = useState(null);

    const { authUser } = useContext(AuthUserContext);

    const [subscriptions, setSubscriptions] = useState({});

    // const [selectedPackage, setSelectedPackage] = useState(null);


    // useEffect(() => {

    //     if (!subscriptions || !subscriptions[plan]) {
    //         return;
    //     }

    //     setSelectedPackage(subscriptions[plan].pkg);

    // }, [plan, subscriptions]);

    const onRadioChange = e => {
        const newVal = e.target.value;
        setPlan(newVal);
    }

    useEffect(() => {
        const fetchOfferings = async () => {

            try {
                const fetchedOfferings = await Purchases.getOfferings();
                if (fetchedOfferings.current && fetchedOfferings.current.availablePackages.length > 0) {
                    setOfferings(fetchedOfferings.current);
                    const newSubscriptions = {};
                    fetchedOfferings.current.availablePackages.forEach(pkg => {
                        const key = pkg.identifier.includes('monthly') ? 'monthly' : 'yearly';
                        newSubscriptions[key] = {
                            id: pkg.identifier,
                            name: key.charAt(0).toUpperCase() + key.slice(1),
                            price: pkg.product.priceString, // This will be localized
                            oldPrice: pkg.product.priceString, // Modify if you have an old price to show
                            period: pkg.packageType.toLowerCase().includes('annual') ? 'per year' : 'per month',
                            displayName: `${pkg.product.title}`,
                        };
                    });
                    setSubscriptions(newSubscriptions);
                } else {
                    console.error("No offerings or packages found");
                }
            } catch (error) {
                console.error("Failed to fetch offerings:", error);
            }

            // finally {
            //     setLoading(false);
            // }
        };

        fetchOfferings();
    }, []);


    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <Card
                bordered={false}
                style={{ width: 240, textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            >

                <Radio.Group
                    onChange={onRadioChange}
                    value={plan}
                    buttonStyle="solid"
                    style={{ marginBottom: 16, width: '100%' }}
                >
                    {['Monthly', 'Yearly'].map(value => (
                        <Radio.Button value={value.toLowerCase()} key={value} style={{ textAlign: 'center', width: "50%" }}>
                            {value}
                        </Radio.Button>
                    ))}
                </Radio.Group>

                {subscriptions[plan] && (
                    <>
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {subscriptions[plan].displayName}
                        </Title>
                        <Title level={2} style={{ marginBottom: -8 }}>
                            {subscriptions[plan].price}
                        </Title>
                        <Text type="secondary" style={{ fontSize: 14 }}>
                            {subscriptions[plan].period}
                        </Text>
                        <div style={{ marginTop: 20 }}>
                            <CheckoutButtonNative
                                type="primary"
                                block
                                email={authUser?.email}
                                promptLogin={props.promptLogin}
                                disabled={!authUser}
                                offerings={offerings}
                                level={plan}
                                buttonText='Checkout'
                            />
                        </div>
                        {/* <div style={{ marginTop: 10, fontSize: 10 }}>
                            <span>* Prices are in USD. Other currencies are also accepted.</span>
                        </div> */}
                    </>
                )}

            </Card>
        </div>
    );
};

export default PricingTable;
