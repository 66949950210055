import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import { message, Typography, Alert } from 'antd';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useScreen, useNetwork } from '../Contexts';
import { ref, get, remove, update, push, runTransaction, query, orderByKey, startAt, set } from 'firebase/database';

import ReactMarkdown from 'react-markdown';

import { sendChat } from '../../functions';
import ChatModule from './chatModule';
import ChatList from './chatList';
import EventDetailsModal from './eventDetailsModal';
import { CustomTour } from './customTour';
import WelcomeModal from './welcomeModal';
import FloatMenu from './floatMenu';
import useFirebaseToken from './useFirebaseToken';
import RateAppTrigger from './rateApp';
import { Capacitor } from '@capacitor/core';

const { Text } = Typography;

const TIMEOUT_DURATION = 30000;  // 30 seconds

const platform = Capacitor.getPlatform() || "web";


const Chat = () => {

    const [firebaseToken, refreshToken] = useFirebaseToken();

    const { authUser, setAuthUser } = useContext(AuthUserContext);

    const targetComponentRef = useRef(null);
    const floatButtonRef = useRef(null);

    // state var to track that one message is being processed, hence none else should also be processed
    const [answerLoading, setAnswerLoading] = useState(false);
    const [chat, setChat] = useState(Array(3).fill({ text: '' })); // use a filler to add a skeleton
    const [chatHistoryLoading, setChatHistoryLoading] = useState(true); // start as true if you want to immediately show a loading state
    const [responseTime, setResponseTime] = useState(null); // start as true if you want to immediately show a loading state

    const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false);
    // const [isChatModalVisible, setIsChatModalVisible] = useState(false);

    const [eventDetailsMap, setEventDetailsMap] = useState({});

    const [teachBotTarget, setTeachBotTarget] = useState(null);

    const [messageQueue, setMessageQueue] = useState([]);
    const [tourOpen, setTourOpen] = useState(false);

    const { width: screenWidth, height: screenHeight, isTouch } = useScreen();

    const { isOnline } = useNetwork();

    // If internet connection is lost, mark all sending messages as failed
    useEffect(() => {
        // Directly use isOnline to manage chat message statuses
        if (!isOnline) {
            setChat(prevChat => prevChat.map(item =>
                item.status === 'sending' ? { ...item, status: 'failed' } : item
            ));
        }
        // This will re-run only when isOnline changes
    }, [isOnline]);





    useEffect(() => {
        if (targetComponentRef.current) {
            // Get the position of the target component relative to the document
            const topPosition = targetComponentRef.current.getBoundingClientRect().top + window.scrollY;

            // Scroll to the target component
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    }, []);


    // const handleTeachBotSelection = useCallback((target) => {
    //     // console.log('handle teach bot selection to target:', target);
    //     setTeachBotTarget(target);
    // }, []); // No dependencies, since setTeachBotTarget is stable


    const setIsTeachModalVisible = useCallback((visible) => {
        if (!visible) {
            setTeachBotTarget(null);
        }
    }, []);  // No dependencies, since setTeachBotTarget is stable


    const [messageApi, contextHolder] = message.useMessage();

    const firebase = useContext(FirebaseContext);


    const user = firebase.auth.currentUser;
    const uid = user?.uid;
    const { db } = firebase;

    const chatListRef = useRef(null);


    const removeFromEdits = useCallback(async (itemId) => {
        try {
            // Reference to the item
            const itemRef = ref(db, `u_edits/${uid}/${itemId}`);

            const ofSpanRef = ref(db, `u_edits/${uid}/${itemId}/of_span`);

            // Get the of_span data
            const snapshot = await get(ofSpanRef);
            if (snapshot.exists()) {
                const ofSpanData = snapshot.val();

                // Iterate over all targetIds in the of_span data
                const removalPromises = Object.keys(ofSpanData).map(targetId => {
                    // Path to the corresponding span entry
                    const targetSpanRef = ref(db, `u_edits/${uid}/${targetId}/span/${itemId}`);
                    return remove(targetSpanRef);
                });

                // Execute all removals concurrently
                await Promise.all([...removalPromises, remove(itemRef)]);
            } else {
                // If no of_span data exists, just remove the item
                await remove(itemRef);
            }
        } catch (error) {
            console.error("Error removing from edits:", error);
            throw error; // Re-throw if you want to handle this error further up in the call stack.
        }
    }, [uid, db]);


    const handleTeachModalFinish = useCallback(
        async (oldVal, newVal, removals, updates, additions, oldText, newText, teachBot, chatTime) => {
            if (newVal.length === 0) { return; } // Don't process empty edits
            let newItem = [...newVal]; // Copy to manage updates locally

            setEventDetailsMap(prev => ({ ...prev, [chatTime]: newItem }));

            const promises = [];

            if (!newText && removals.length === 0 && updates.length === 0 && additions.length === 0) {
                // console.log('No new text, and no changes detected between the old and new values.');
                return;
            }

            if (newText && newText !== oldText) {
                const correctionsTextRef = ref(db, `u_corrections/${uid}/${chatTime}/text`);
                promises.push(runTransaction(correctionsTextRef, currentData => {
                    return currentData === null ? oldText : undefined;
                }));
                setChat((currentChat) =>
                    currentChat.map((chatItem) =>
                        chatItem.time === chatTime ? { ...chatItem, text: newText } : chatItem
                    )
                );
            }

            if (removals.length || updates.length || additions.length) {

                const correctionsRef = ref(db, `u_corrections/${uid}/${chatTime}`);
                // Handling corrections for audit trail
                if (oldVal.length === 0) {
                    promises.push(update(correctionsRef, { no_original_records: true }));
                } else {
                    oldVal.forEach(record => {
                        const { key: recordKey, ...recordData } = record;
                        const recordCorrectionsRef = ref(db, `u_corrections/${uid}/${chatTime}/error_records/${recordKey}`);
                        promises.push(runTransaction(recordCorrectionsRef, currentData => {
                            return currentData === null ? recordData : undefined;
                        }));
                    });
                }


                promises.push(update(correctionsRef, { teachBot }));
            }

            await Promise.all(promises);


            // Concurrently process removals, and updates
            const removalUpdatePromises = [
                ...removals.map(key =>
                    remove(ref(db, `u_records/${uid}/${key}`))),
                ...removals.map(key =>
                    removeFromEdits(key)),
                ...updates.map(key => {
                    const updatedData = newVal.find(item => item.key === key);
                    const { key: newKey = updatedData?.key ?? null, ...newData } = updatedData || {};
                    return update(ref(db, `u_records/${uid}/${key}`), newData);
                })
            ];

            if (newText && newText !== oldText) {
                // const correctionsTextRef = ref(db, `u_corrections/${uid}/${chatTime}/text`);
                const textRef = ref(db, `u_chats/${uid}/${chatTime}`);
                removalUpdatePromises.push(update(textRef, { text: newText }));
            }


            await Promise.all(removalUpdatePromises);


            if (additions.length > 0) {
                const additionPromises = additions.map(newItemIdx => {
                    const newData = newItem[newItemIdx];
                    const recordsRef = ref(db, `u_records/${uid}`);
                    return push(recordsRef, newData).then(newRecordRef => {
                        newItem[newItemIdx] = { ...newData, key: newRecordRef.key };  // Update the key in the newItem array
                        // return newRecordRef.key;  // Return the new key for further processing if necessary
                    });
                });
                // Wait for all addition operations to complete
                await Promise.all(additionPromises);

            }




            // Update response in u_chats
            if (removals.length > 0 || additions.length > 0) {
                // const oldResponse = oldVal.map(item => item.key);

                const keptKeys = newItem.map(item => item.key);

                // Construct the update object with continuous indices for kept keys
                let responseUpdateObject = keptKeys.reduce((acc, pushId, index) => {
                    acc[String(index)] = pushId; // Keep continuous indices for kept items
                    return acc;
                }, {});

                // Append nulls for deleted keys to indicate deletion in Firebase
                removals.forEach((_, index) => {
                    responseUpdateObject[String(keptKeys.length + index)] = null; // Mark for deletion
                });


                const responseRef = ref(db, `u_chats/${uid}/${chatTime}/response`);
                await update(responseRef, responseUpdateObject);
                setChat((currentChat) =>
                    currentChat.map((chatItem) =>
                        chatItem.time === chatTime ? { ...chatItem, response: keptKeys } : chatItem
                    )
                );
            }

            // console.log('Updates to chat and records completed successfully.');
        },
        [db, uid, removeFromEdits] // Dependencies
    );



    const fetchEventDetailsForItem = useCallback(async (item) => {

        let fetchedDetails = [];

        if (eventDetailsMap[item.time]) {
            // console.log('Using cached details');
            return eventDetailsMap[item.time];
        }
        else if (typeof item.response === 'string' && item.response !== '') {
            // setEventDetailsMap(prev => ({ ...prev, [item.time]: { details: [item.response], textTime: item.time, text: item.text, timezone: item.timezone, recorded: false } }));
            setEventDetailsMap(prev => ({ ...prev, [item.time]: item.response }));
            return [item.response];
        } else if (uid && item.response) {
            // technically user should already be populated, since to click on a chat item, the user must be logged in
            const pushIds = item.response; // Assuming this is an array of push IDs

            for (let pushId of pushIds) {
                const eventRef = ref(db, `u_records/${uid}/${pushId}`);
                try {
                    const snapshot = await get(eventRef);
                    if (snapshot.exists()) {
                        const eventData = snapshot.val();
                        fetchedDetails.push({ ...eventData, key: pushId });
                    }
                } catch (error) {
                    console.error("Error fetching event details:", error);
                }
            }
            // setEventDetailsMap(prev => ({ ...prev, [item.time]: { details: fetchedDetails, textTime: item.time, text: item.text, timezone: item.timezone, recorded: true } }));
            setEventDetailsMap(prev => ({ ...prev, [item.time]: fetchedDetails }));

            return fetchedDetails;
        }

        return fetchedDetails;

    }, [uid, db, eventDetailsMap]);


    const handleTeachBot = useCallback((item) => {
        fetchEventDetailsForItem(item);

        setTeachBotTarget(item.time);


    }, [fetchEventDetailsForItem, setTeachBotTarget]);




    const scrollToBottom = useCallback(() => {
        const scroll = chatListRef.current;
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
            // scroll.scrollTo({ top: scroll.scrollHeight, behavior: 'smooth' });
        }
    }, []); // No dependencies, so it only creates the function once


    // if showing details and if it's the last item, then scroll to the bottom
    const handleDetailsToggle = useCallback(
        (itemTime) => {
            const lastIdxTime = chat[chat.length - 1]?.time; // Optional chaining in case chat is empty
            if (itemTime === lastIdxTime) {
                scrollToBottom();
            }

            // else {
            //     chatListRef.current?.scrollBy({
            //         top: 80, // or any positive or negative value you want to scroll by vertically
            //         left: 0, // or any positive or negative value you want to scroll by horizontally
            //         behavior: 'smooth' // This enables smooth scrolling
            //     });
            // }
        },
        [chat, scrollToBottom] // Dependencies
    );





    const prevChatsLength = useRef(chat.length);

    useEffect(() => {
        if (chat.length > prevChatsLength.current) {
            scrollToBottom();
        }

        if (chat.length !== prevChatsLength.current) {
            prevChatsLength.current = chat.length;
        }
        // Update the previous length for the next render

    }, [chat.length, scrollToBottom]); // Dependency only on chats.length



    // if navigate away, save pending and failed messages to LocalStorage all as failed
    useEffect(() => {
        // Function to save pending and failed messages to LocalStorage
        const savePendingMessages = () => {
            const pendingMessages = chat.filter(msg => msg.status === 'sending' || msg.status === 'failed');

            // Update all 'sending' messages to 'failed'
            const updatedMessages = pendingMessages.map(msg => ({
                ...msg,
                status: 'failed'
            }));

            localStorage.setItem('pendingMessages', JSON.stringify(updatedMessages));

        };

        // Add event listener for beforeunload
        window.addEventListener('beforeunload', savePendingMessages);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', savePendingMessages);
            // Optionally save on cleanup if you expect unloads might not be user-triggered
            savePendingMessages();
        };
    }, [chat]);



    useEffect(() => {


        const fetchChatHistory = async () => {
            try {

                if (uid && db) {
                    let firebaseArray = [];


                    if (isOnline) {
                        // Reference to the messages node in Firebase under u_chats/{uid}
                        const userChatRef = ref(db, 'u_chats/' + uid);

                        const n = 3; // Number of days to fetch chat history for

                        const limitedTimeAgo = Date.now() - (n * 24 * 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds; 24 hours and n days


                        // Create a query that starts at records from one hour ago
                        const recentMessagesQuery = query(userChatRef, orderByKey(), startAt(String(limitedTimeAgo)));

                        // Get chat history from Firebase using the new approach
                        get(recentMessagesQuery).then((snapshot) => {
                            if (snapshot.exists()) {
                                const firebaseData = snapshot.val();

                                firebaseArray = firebaseData
                                    ? Object.entries(firebaseData).map(([key, value]) => {
                                        // Check if response is an object and convert it back to an array if so
                                        // sometimes if index is messed up I wonder if response would be an object
                                        if (value.response && typeof value.response === 'object' && !Array.isArray(value.response)) {
                                            const responseArray = Object.values(value.response); // Convert object to array
                                            return { 'time': parseInt(key), ...value, response: responseArray };
                                        }
                                        // else if (value.response && Array.isArray(value.response)) {
                                        //     console.log('is array:', value.response);
                                        // }
                                        return { 'time': parseInt(key), ...value };
                                    })
                                    : [];
                            }

                            // Combine firebaseArray with local storage data

                            // Load any pending messages from LocalStorage
                            const pendingMessages = JSON.parse(localStorage.getItem('pendingMessages')) || [];

                            // console.log('pendingMessages time type', typeof pendingMessages[0]?.time);

                            // Merge and remove duplicates, assuming `time` is a unique identifier
                            const mergedMessages = [...firebaseArray, ...pendingMessages].reduce((acc, item) => {
                                if (!acc.some(msg => msg.time === item.time)) {
                                    acc.push(item);
                                }
                                return acc;
                            }, []).sort((a, b) => a.time - b.time); // Sort by time after merging



                            // Combine firebaseArray with props.data
                            // const combinedData = [...data, ...firebaseArray];
                            // console.log('combinedData', combinedData);
                            // setChat(firebaseArray);
                            setChat(mergedMessages);
                            // localStorage.removeItem('pendingMessages');


                        }).catch((error) => {
                            console.error("Error fetching chat history:", error);
                        });
                    } else {

                        // messageApi.open({
                        //     type: 'warning',
                        //     content: 'You are offline. Can\'t fetch earlier notes. You can keep writing though — your notes will be processed when you are back online.',
                        //     duration: 5,
                        //     style: {
                        //         marginTop: '10vh',
                        //     }
                        // });

                        const pendingMessages = JSON.parse(localStorage.getItem('pendingMessages')) || [];
                        setChat(pendingMessages);
                        // localStorage.removeItem('pendingMessages');
                    }


                    setChatHistoryLoading(false);

                    // if (authUser ? !authUser?.settings?.chat_tour_seen : true) {
                    //     setIsWelcomeModalVisible(true);
                    // }

                    if (authUser?.settings?.chat_tour_seen == null || !authUser?.settings?.intent) {
                        setIsWelcomeModalVisible(true);
                    }
                }

            } catch (error) {
                setChatHistoryLoading(false);
                console.error("Error initializing chat fetch:", error);
            }
        };


        fetchChatHistory();


        // compareRecords();
        // oneTimeReverseMappingEdits();

    }, [uid, db, isOnline, authUser?.settings?.chat_tour_seen]);




    const markdownComponents = useMemo(() => ({
        // Other components as needed...

        li: ({ node, ...props }) => (
            <li>
                <Text style={{ fontSize: 'smaller' }}>{props.children}</Text>
            </li>
        ),
    }), []); // Dependencies array is empty because we assume markdownComponents doesn't depend on any props or state


    const showWarning = useCallback((val, type, duration = 3, icon = null) => {

        messageApi.open({
            type,
            content: <div style={{ textAlign: 'left' }}>
                <ReactMarkdown className='react-markdown' components={markdownComponents}>
                    {val}</ReactMarkdown>
            </div >,
            duration,
            icon
        });

    }, [messageApi, markdownComponents]);



    const handleSendOneMessage = useCallback(async (messageObj) => {
        const startTimestamp = Date.now();
        setResponseTime(null);

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const firebaseToken = await user.getIdToken();
        const utcTimestamp = messageObj.time;
        const msg = messageObj.text;
        const resend = messageObj.resend || false;

        try {
            // Check if network is online before attempting to send
            // thrown error will be caught in the catch block
            if (!isOnline) throw new Error("Network is offline");

            const resPromise = sendChat(msg, timezone, firebaseToken, utcTimestamp, platform, resend);

            const res = await Promise.race([
                resPromise,
                new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), TIMEOUT_DURATION))
            ]);

            if (res?.status === 200) {
                const message = res.data && res.data.message;
                const recorded = Array.isArray(message);
                const cmessage = recorded ? message.map(m => m.key) : message;

                // const details = recorded ? message : [message];

                // Update the relevant chat item
                setChat(prevChat => prevChat.map(item =>
                    item.time === utcTimestamp ? { ...item, response: cmessage, status: 'sent' } : item
                ));

                setEventDetailsMap(prev => ({ ...prev, [utcTimestamp]: message }));

                if (recorded) {
                    // showMsg(message, utcTimestamp, 'success', 4, <><CheckCircleFilled fill='green' />Recorded</>);
                } else {
                    showWarning('Didn\'t manage to record', 'warning', 2);
                    console.error(message);
                }

                setResponseTime(Date.now() - startTimestamp);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            // Update the message status to failed
            setChat(prevChat => prevChat.map(item =>
                item.time === utcTimestamp ? { ...item, status: 'failed' } : item
            ));
        }
    }, [firebaseToken, isOnline, showWarning, setChat, setEventDetailsMap]); // Dependencies



    // Process queued messages
    useEffect(() => {


        const processQueue = async () => {
            if (messageQueue.length > 0 && !answerLoading && uid) {
                const currentMessage = messageQueue[0];
                setAnswerLoading(true); // Start processing

                await handleSendOneMessage(currentMessage);
                // On success, remove the message from the queue
                setMessageQueue(prev => prev.slice(1));
                setAnswerLoading(false); // Finish processing
            }
        };

        processQueue();

    }, [messageQueue, answerLoading, uid, handleSendOneMessage]);


    // Adjust handleSendClick to queue messages and add immediately to chatList
    const handleSendClick = useCallback((msg) => {
        const utcTimestamp = Date.now();
        const newMessage = { text: msg, status: 'sending', time: utcTimestamp };

        // Add message immediately to chatList
        setChat(prevChat => [...prevChat, { ...newMessage }]);

        // Add to queue if not exceeding 3 messages
        setMessageQueue((prevQueue) => {
            if (prevQueue.length < 3) {
                return [...prevQueue, newMessage];
            } else {
                return prevQueue; // Optionally handle or notify when queue is full
            }
        });

    }, []);


    const handleResend = useCallback(
        async (utcTimestamp, resendFlag = true) => {
            // Find the message to resend using the utcTimestamp
            const messageToResend = chat.find((item) => item.time === utcTimestamp);

            if (!messageToResend) {
                console.error("Message not found for resending");
                return;
            }

            // Check if queue is full before adding
            setMessageQueue((prevQueue) => {
                if (prevQueue.length < 3) {
                    // Set the message as queued again
                    setChat((prevChat) =>
                        prevChat.map((item) =>
                            item.time === utcTimestamp ? { ...item, status: 'sending' } : item
                        )
                    );

                    // Add to queue for resending
                    return [...prevQueue, { ...messageToResend, status: 'sending', resend: resendFlag }];
                } else {
                    console.warn("Queue is full, cannot resend at this moment.");
                    showWarning('Queue is full, cannot resend at this moment', 'warning', 3);
                    return prevQueue; // Optionally handle or notify when queue is full
                }
            });
        },
        [chat, showWarning]
    );


    // once we are back online, automatically queue failed messages
    useEffect(() => {
        // Function to automatically queue failed messages when back online

        const autoQueueFailedMessages = () => {
            const failedMessages = chat.filter(msg => msg.status === 'failed');
            failedMessages.forEach(msg => {
                handleResend(msg.time, false);
            });
        };

        if (isOnline) {
            // token needs refreshing, since perhaps we were offline for a while and the token expired (every hour)
            refreshToken().then(() => {
                // Ensure token is refreshed before queuing failed messages
                autoQueueFailedMessages();
            });
        }
    }, [isOnline, chat, handleResend, refreshToken]);




    const onCloseTour = useCallback(() => {
        setTourOpen(false);
        if (targetComponentRef.current) {
            const textarea = targetComponentRef.current.querySelector('textarea');
            textarea?.focus();
        }

    }, []);

    const onBeginTour = useCallback((bl) => {

        if (bl) {
            setTourOpen(true);
        }

        setIsWelcomeModalVisible(false);


    }, []);



    const onFinishTour = useCallback(() => {
        if (authUser && !authUser.settings?.chat_tour_seen) {
            setAuthUser({ ...authUser, settings: { ...authUser.settings, chat_tour_seen: true } });
            const userRef = ref(db, `u_settings/${uid}`);
            update(userRef, { chat_tour_seen: true }).catch((error) => { console.error(error) });
        }
    }, [authUser, setAuthUser, db, uid]);


    const handleDelete = useCallback(async (item) => {

        // Update local state to remove the item that's the same as the one to be deleted
        setChat(currentChat => currentChat.filter(listItem => listItem.time !== item.time));

        if (user) {
            const { uid } = user;

            // Delete the chat item from Firebase
            const chatRef = ref(db, `u_chats/${uid}/${item.time}`);
            await remove(chatRef).catch(error => console.error("Error deleting message:", error));

            const correctionRef = ref(db, `u_corrections/${uid}/${item.time}`);
            await remove(correctionRef).catch(error => console.error("Error deleting correction:", error));



            // If there are associated records, delete them too
            if (Array.isArray(item.response)) {
                for (const itemId of item.response) {
                    const recordRef = ref(db, `u_records/${uid}/${itemId}`);
                    await removeFromEdits(itemId);
                    await remove(recordRef).catch(error => console.error("Error deleting record:", error));
                }
            }


        }
    }, [user, db, removeFromEdits]); // Dependencies

    const removeMessageFromQueue = useCallback((timeKey) => {
        setMessageQueue((currentQueue) => currentQueue.filter((obj) => obj.time !== timeKey));
    }, []);


    const handleSaveIntent = useCallback((selectedOptions, otherText) => {
        const intentRef = ref(db, 'u_settings/' + uid + '/intent');
        const recordObj = { selectedOptions: selectedOptions || null, otherText: otherText || null };
        setAuthUser({ ...authUser, settings: { ...authUser.settings, intent: recordObj } });
        set(intentRef, recordObj);
    }, [db, uid, authUser, setAuthUser]);


    return (
        <div>

            {contextHolder}

            <FloatMenu
                top={50}
                screenWidth={screenWidth}
                screenHeight={screenHeight}
                tourOpen={tourOpen}
                setTourOpen={setTourOpen}
            />

            {!isOnline && <Alert
                type="warning"
                message="You are offline"
                description="Earlier notes can't be fetched, and some features don't work. You can keep writing though — your notes will be processed when you are back online."
                style={{ marginBottom: '1em' }}
                closable
            />}


            <div
                ref={chatListRef}
                style={{
                    maxHeight: screenHeight - 400, // Set a fixed height here
                    overflowY: 'auto',   // Enable vertical scrolling
                    width: '100%',
                }}>


                <ChatList
                    chat={chat}
                    chatHistoryLoading={chatHistoryLoading}
                    handleResend={handleResend}
                    handleDelete={handleDelete}
                    handleDetailsToggle={handleDetailsToggle}
                    fetchEventDetailsForItem={fetchEventDetailsForItem}
                    handleTeachBot={handleTeachBot}
                    eventDetailsMap={eventDetailsMap}
                    isOnline={isOnline}
                />


            </div>

            {
                chatHistoryLoading ? null :
                    <EventDetailsModal
                        eventDetails={eventDetailsMap[teachBotTarget]}
                        chatEntry={chat.find((item) => item.time === teachBotTarget)}
                        isVisible={teachBotTarget !== null}
                        setIsVisible={setIsTeachModalVisible}
                        onFinish={handleTeachModalFinish}
                    />
            }

            <div style={{ marginTop: 30 }}
            >
                <WelcomeModal
                    modalVisible={isWelcomeModalVisible}
                    onBeginTour={onBeginTour}
                    handleSaveIntent={handleSaveIntent}
                />
            </div>


            <div style={{ marginTop: 30 }}
                ref={targetComponentRef}>
                <ChatModule
                    handleSendClick={handleSendClick}
                    messageQueue={messageQueue}
                    removeMessageFromQueue={removeMessageFromQueue}
                    screenWidth={screenWidth}
                    screenHeight={screenHeight}
                    isTouch={isTouch}
                    isOnline={isOnline}
                />
            </div>


            <CustomTour
                // ref1={floatButtonRef}
                // ref2={chatListRef}
                open={tourOpen}
                onCloseTour={onCloseTour}
                onFinishTour={onFinishTour}
                screenWidth={screenWidth}
            />

            {Capacitor.isNativePlatform() &&
                <RateAppTrigger />}

            {/* <Text type="secondary">
                {responseTime ? `Response time: ${(responseTime / 1000).toFixed(1)} s` : 'Calculating...'}
            </Text> */}

        </div >
    );

};

export default Chat;
