export const LANDING = "/";
// export const PROFILE = "/profile";
// export const ADMIN = '/admin';
export const SETTINGS = "/settings";
export const HOME = "/home";
export const DAY = "/day";
export const SPAN = "/span";
export const ACCOUNT = "/account";
export const CHAT = "/chat";
export const PAGE404 = "/404";
export const PAGE403 = "/403";

export const PAYMENTSUCCESS = "/paymentsuccess";
export const ERROR = "/error";
export const PAYMENTCANCELLED = "/paymentcancelled";
export const NOTICE = "/notice";
export const PAYMENT_ERROR = "/paymentError";
export const AUTHHANDLER = "/auth/action";
export const CHECKOUT = "/checkout";
export const MEMBERSHIP = "/membership";

export const ABOUT = "/about";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";
export const HELP = "/help";
export const DISCLAIMER = "/disclaimer";
export const CONTACT = "/contact";
export const FAQ = "/faq";

export const MARKETING = "/hello";

