import React, { useEffect, useState, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import AuthUserContext from './createdContexts';
import { FirebaseContext } from '../Firebase/hooks'; // adjust the import path accordingly
import { ref, get } from 'firebase/database';



async function fetchUserAndSettingsFromDB(db, uid) {
    // Create references for user and settings
    const userRef = ref(db, 'users/' + uid);
    const settingsRef = ref(db, 'u_settings/' + uid);

    // Fetch both datasets concurrently
    const [userSnapshot, settingsSnapshot] = await Promise.all([
        get(userRef),
        get(settingsRef)
    ]);

    // Check if user data exists and merge with settings if available
    if (userSnapshot.exists() || settingsSnapshot.exists()) {

        const userData = userSnapshot.exists() ? userSnapshot.val() : null;
        const settingsData = settingsSnapshot.exists() ? settingsSnapshot.val() : null;

        // Combine user data and settings into a single object
        return {
            ...userData,
            settings: settingsData  // Storing settings under a nested 'settings' property
        };

    } else {
        // Return null if no data is found
        return null;
    }
}



const AuthUserProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { auth, db } = useContext(FirebaseContext);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {

                // console.log('on Auth state changed, triggered in AuthUserProvider.')
                // Fetch user data from the database
                const dbUser = await fetchUserAndSettingsFromDB(db, user.uid);

                // Merge Firebase auth data with database user data
                const mergedUser = {
                    ...user,
                    ...dbUser,
                };
                setAuthUser(mergedUser);
            } else {
                setAuthUser(null);
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, [auth, db]);

    return (
        <AuthUserContext.Provider value={{ authUser, setAuthUser, isLoading }}>
            {children}
        </AuthUserContext.Provider>
    );
};

export default AuthUserProvider;

