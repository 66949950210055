import React, { useState, useRef } from 'react';
import { Input, Radio, Button, Flex, message } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";

import { submitWebform } from '../../functions';

const FeedbackForm = () => {
    const [loading, setLoading] = useState(false);
    const [selectedFeedbackType, setSelectedFeedbackType] = useState('newFeatures');
    const [inputValue, setInputValue] = useState('');
    // const [captchaToken, setCaptchaToken] = useState(null);

    const recaptchaRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    const placeholders = {
        newFeatures: "Stuff I wish the site/app could do that it doesn't currently",
        bugReport: "Glitches I encountered",
        generalFeedback: "Comments, suggestions, gripes"
    };

    // const handleCaptcha = (token) => {
    //     setCaptchaToken(token);
    // };



    // Executes reCAPTCHA when the form is submitted
    const executeRecaptcha = async () => {
        try {
            const token = await recaptchaRef.current.executeAsync();
            return token;
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            throw error; // Propagate the error so that it can be handled in the calling function
        }
    };


    const handleSubmit = async () => {
        if (!inputValue.trim()) {
            messageApi.open({
                type: 'error',
                content: 'Please enter your feedback.',
                duration: 3,
            });
            return;
        }

        setLoading(true);

        try {
            const recaptchaToken = await executeRecaptcha();

            if (!recaptchaToken) {
                throw new Error("Failed to obtain ReCAPTCHA token");
            }

            await submitWebform({
                recaptchaToken,
                formdata: {
                    bodytext: inputValue.trim(),
                    subject: selectedFeedbackType,
                }
            }, true);

            setLoading(false);

            messageApi.open({
                type: 'success',
                content: 'Message sent',
                duration: 3,
            });

            setInputValue('');
            recaptchaRef.current.reset();

            // setCaptchaToken(null);

        } catch (error) {
            console.error('Error during form submission:', error);
            messageApi.open({
                type: 'error',
                content: 'Message not sent. Please try again.',
                duration: 3,
            });
            setLoading(false);
            recaptchaRef.current.reset();
        }
    };




    return (
        <div>
            {contextHolder}
            <Radio.Group
                onChange={e => setSelectedFeedbackType(e.target.value)}
                value={selectedFeedbackType}
                style={{ marginBottom: 16 }}

            >
                <Flex vertical gap="small">
                    <Radio value="newFeatures">I want new features</Radio>
                    <Radio value="bugReport">I have caught a bug</Radio>
                    <Radio value="generalFeedback">General feedback</Radio>
                </Flex>
            </Radio.Group>
            <Input.Group compact style={{ display: 'flex', marginBottom: 16 }}>
                <Input
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    placeholder={placeholders[selectedFeedbackType]}
                    style={{ flexGrow: 1 }}
                    onPressEnter={handleSubmit}
                />
                <Button type="primary" onClick={handleSubmit} loading={loading}>
                    Send
                </Button>
            </Input.Group>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                // onChange={handleCaptcha}
                size="invisible"
            />
        </div>
    );
};

export default FeedbackForm;


