import React, { useCallback, useContext, useEffect } from 'react';
import { Layout, Typography, Flex } from 'antd';
import Chat from './Chat';

// import RealtimeTable from './table';
import { useAuthorization, withEmailVerification, DateContext } from '../Contexts';

import { LayoutWithNav } from '../Navigation';
import { formatDurationCompact } from './utils';

import { siteName } from '../../constants';
import moment from 'moment-timezone';

// import ChatDemo from './ChatDemo';

const { Content, Footer } = Layout;

const { Title } = Typography;


const ChatPage = () => {

    const authCondition = useCallback(authUser => !!authUser, []);
    useAuthorization(authCondition);


    const { setDate } = useContext(DateContext);


    useEffect(() => {
        setDate(moment().format('YYYY-MM-DD'));  // Sets the context date to today
    }, [setDate]);



    return (
        <LayoutWithNav>
            <Layout style={{ flexGrow: 1 }}>
                <Content>


                    <Flex gap="middle" align="flex-end" justify="space-evenly" >
                        <Title level={3} style={{ textAlign: 'center', marginTop: '50px', marginBottom: '20px' }}>My notes</Title>

                    </Flex>





                    <div style={{ maxWidth: 600, padding: "24px 16px 10px 16px", margin: "auto", backgroundColor: 'rgba(255, 255, 255, 0)' }}>

                        <Chat />
                        {/* <ChatDemo /> */}

                    </div>





                </Content>
                <Footer style={{ textAlign: 'center', padding: '14px', fontSize: "14px", position: 'sticky' }}>©{new Date().getFullYear()} {siteName} created by Spearmint Labs</Footer>
            </Layout>
        </LayoutWithNav>
    );
};

export { formatDurationCompact };

export default withEmailVerification(ChatPage);



// const messages = [
//     { sender: 'Assistant', text: 'Hello! I\'m your assistant who records time for you.\nLet me know the event and time that you want me to record.\nYou can also just tell me the event, and I\'ll assume it happened just now.' },
//     // { sender: 'User', text: 'I\'m doing well. Thanks for asking!' },
// ];
