import React from 'react';
import { Button, ConfigProvider, theme } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';
import Texty from 'rc-texty';
import { Capacitor } from '@capacitor/core';

const isNative = Capacitor.isNativePlatform();

const styles = {
    banner: {
        width: '100%',
        height: `calc(100vh - 80px)`,  // adjust according to the index.js complete page's header height
        position: 'relative',
        textAlign: 'center',
        borderColor: '#666',
        backgroundImage: 'url("/background.svg")',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
    },
    textWrapper: {
        display: 'inline-block',
        position: 'absolute',
        top: '20%',
        margin: 'auto',
        left: 0,
        right: 0,
        fontSize: '14px',
        color: '#fff', // Assuming template-text-color-light is white
        width: '550px',
    },
    title: {
        width: '350px',
        minHeight: '80px',
        margin: 'auto',
        display: 'inline-block',
        fontSize: '40px',
        position: 'relative',
        marginTop: '60px',
    },
    content: {
        marginTop: '20px',
        marginBottom: '40px',
        wordWrap: 'break-word',
        minHeight: '24px',
        fontSize: '20px',  // Updated font size, adjust as needed
        color: '#fff'  // Assuming this is the color of the "Learn More" button text
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',  // Space between buttons
        marginBottom: '20px',  // Adjust as needed to reduce the gap
    },
    // button: {
    //     border: '1px solid #fff',
    //     color: '#fff',
    //     background: 'transparent',
    //     boxShadow: '0 0 0 transparent',
    //     fontSize: '16px',
    //     height: '40px',
    //     transition: 'background 0.45s ease-out, box-shadow 0.45s ease-out',
    //     '&:hover': {
    //         background: 'rgba(255, 255, 255, 0.1)',
    //         boxShadow: '0 0 10px rgba(50, 250, 255, 0.75)',
    //     }
    // },
    signUpText: {
        textAlign: 'center',
        color: '#fff',
        fontSize: '14px',
        marginTop: '8px',  // Space between the button and text
    },
    icon: {
        bottom: '40px',
        fontSize: '30px',
        position: 'absolute',
        left: '50%',
        marginLeft: '-12px',
        color: '#fff', // Assuming template-text-color-light is white
    }
};





class Banner extends React.PureComponent {

    scrollToContent = (targetId) => {
        const target = document.getElementById(targetId);
        if (target) {
            window.scrollTo({
                top: target.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    onClickFreeTry = () => {

        this.props.onSignUp();

        //This will trigger the conversion 

        window.gtag('config', 'AW-16760636706');

        window.gtag('event', 'conversion', { 'send_to': 'AW-16760636706/ci3cCL62neQZEKKKjLg-' });
    }

    render() {
        const { dataSource, isMobile } = this.props;
        const mobileStyleAdjustments = isMobile ? {
            backgroundAttachment: 'inherit',
            textWrapper: { width: '90%' },
            title: { width: '90%', left: 0 },
        } : {};

        return (
            <div style={{ ...styles.banner, ...mobileStyleAdjustments }}>
                <ConfigProvider
                    theme={{
                        algorithm: theme.defaultAlgorithm,
                    }}
                >
                    <QueueAnim key="QueueAnim" type={['bottom', 'top']} delay={200} {...dataSource.textWrapper}>
                        <div key="title" style={styles.title}>
                            {typeof dataSource.title.children === 'string' && dataSource.title.children.match(isImg) ? (
                                <img src={dataSource.title.children} width="100%" alt="img" />
                            ) : (
                                dataSource.title.children
                            )}
                        </div>
                        <div key="content" style={styles.content}>

                            <Texty
                                type='flash'
                                mode='smooth'
                                delay={600}
                                interval={30}
                            >
                                {dataSource.content.children}
                            </Texty>
                        </div>

                        <div style={styles.buttonContainer}>

                            {!this.props.signedIn &&

                                <Button ghost className="landing-button-style" onClick={this.onClickFreeTry}>
                                    Try It for Free
                                </Button>

                            }

                            {!isNative &&
                                <Button ghost className="landing-button-style" onClick={() => this.scrollToContent('content0')}>
                                    {dataSource.button.children}
                                </Button>}
                        </div>

                    </QueueAnim >


                    {!isNative && <TweenOne
                        animation={{
                            y: '-=20',
                            yoyo: true,
                            repeat: -1,
                            duration: 1000,
                        }}
                        style={styles.icon}
                    >

                        <DownOutlined onClick={() => this.scrollToContent('content0')} />

                    </TweenOne>}

                </ConfigProvider>
            </div >
        );
    }
}

export default Banner;
