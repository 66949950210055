import React from 'react';

import Texty from 'rc-texty';

import { Row, Col } from 'antd';

import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';

const OverPack = ScrollAnim.OverPack;


const getChildrenToRender = (item, key) => {

    return (
        <OverPack playScale={0.3} key={key}>
            <div style={item.style}>
                <Texty
                    type='alpha'
                    mode='smooth'
                    interval={10}
                >

                    {item.children}

                </Texty>
            </div>
        </OverPack>
    );
};



const ChatSimulationExamples = (props) => {

    const { dataSource, isMobile } = props;
    const { wrapper, titleWrapper, page, OverPack: overPackData, childWrapper } = dataSource;


    const baseStyle = {
        minHeight: '446px',
        overflow: 'hidden',
        height: '100%',
        padding: '64px 24px',
    };

    const mobileStyle = {
        minHeight: '880px',
    };

    const wrapperStyle = {
        ...baseStyle,
        ...(isMobile ? mobileStyle : {}),
    };

    const titleContainerStyle = {
        margin: '0 auto 48px',
        fontSize: '20px',
        textAlign: 'center',  // Ensure that the container for the title is also centered
    };


    return (
        <div style={wrapperStyle}>
            <div style={{ height: '100%' }} id='availability'>
                <div style={titleContainerStyle}>
                    {/* {titleWrapper.children.map(getChildrenToRender)} */}
                    {titleWrapper.children.map((item, index) => getChildrenToRender(item, index))}
                    {/* Available on the phone, the tablet and on the web */}
                </div>
                <OverPack {...overPackData}>

                    {/* <div className="block-wrapper">
                        Available on all these platforms.
                    </div> */}

                    <QueueAnim
                        type="bottom"
                        key="block"
                        leaveReverse
                        component={Row}
                        componentProps={{ gutter: [16, 16] }}
                        delay={500}
                    >
                        {childWrapper.children.map((block, i) => {
                            const blockStyle = {
                                padding: '0 4%',
                                display: 'inline-block',
                                textAlign: 'center',
                                minHeight: '200px',
                                marginBottom: '24px',
                                position: 'relative',
                                top: '25%',
                            };

                            const itemStyle = {
                                width: '100%',
                                height: '100%',
                            };

                            const iconStyle = {
                                width: '200px',
                                height: '200px',
                                margin: 'auto',
                            };


                            const titleStyle = {
                                lineHeight: '32px',
                                margin: '10px auto',
                                fontSize: '20px',
                                textAlign: 'center',  // Ensure the text within the title block is centered
                            };

                            const { children: item } = block;
                            const key = block.name || i.toString();  // Use block.name if available and unique, otherwise use the index

                            return (
                                <Col key={key} span={isMobile ? 24 : block.md} style={blockStyle}>
                                    <div style={itemStyle}>
                                        <a href={item.children[0].link}>
                                            <img style={iconStyle} src={item.children[0].children} alt="" />
                                        </a>
                                        <div style={titleStyle}>{item.children[1].children}</div>
                                        <div>{item.children[2].children}</div>
                                    </div>
                                </Col>
                            );
                        })}
                    </QueueAnim>

                </OverPack>
            </div >
        </div >
    );

};


export default ChatSimulationExamples;


