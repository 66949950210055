import React, { useState } from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Texty from 'rc-texty';
import { Radio, Flex, Space } from 'antd';
import { useScreen } from '../Contexts';
// import BannerAnim, { Element } from 'rc-banner-anim';
// import 'rc-banner-anim/assets/index.css';
import SimulatedChat from './simulatedChat';
import ScrollAnim from 'rc-scroll-anim';

const OverPack = ScrollAnim.OverPack;

// const BgElement = Element.BgElement;


const getChildrenToRender = (item, key) => {

    return (
        <OverPack playScale={0.3} key={key}>
            <div style={item.style}>
                <Texty
                    type='alpha'
                    mode='smooth'
                    interval={10}
                >

                    {item.children}

                </Texty>
            </div>
        </OverPack>
    );
};



const ChatSimulationExamples = (props) => {
    const [slideNum, setSlideNum] = useState(0);

    const { dataSource, isMobile, scrollToBottom } = props;
    const { wrapper, titleWrapper, page, OverPack: overPackData } = dataSource;


    const { width: screenWidth } = useScreen();

    const baseStyle = {
        minHeight: '446px',
        overflow: 'hidden',
        height: '100%',
        padding: '64px 24px',
    };

    const mobileStyle = {
        minHeight: '880px',
    };

    const wrapperStyle = {
        ...baseStyle,
        ...(isMobile ? mobileStyle : {}),
    };

    const titleContainerStyle = {
        margin: '0 auto 48px',
        fontSize: '20px',
        textAlign: 'center',  // Ensure that the container for the title is also centered
    };


    const handlePageChange = e => {
        setSlideNum(e.target.value);
    };

    const onNext = (atEnd = false) => {
        if (atEnd) {
            setSlideNum(0);
        } else {
            setSlideNum(slideNum + 1);
        }
    };



    return (
        <div style={wrapperStyle}>
            <div style={{ height: '100%' }} id='contentSim'>
                <div style={titleContainerStyle}>
                    {/* {titleWrapper.children.map(getChildrenToRender)} */}
                    {titleWrapper.children.map((item, index) => getChildrenToRender(item, index))}
                </div>
                <OverPack {...overPackData}>


                    <div className="block-wrapper">

                        {screenWidth <= 500 ?

                            <Radio.Group value={slideNum} onChange={handlePageChange} buttonStyle="solid">
                                <Space direction="vertical">

                                    <Radio.Button value={0}>Just say it</Radio.Button>
                                    <Radio.Button value={1}>Remember when?</Radio.Button>


                                    <Radio.Button value={2}>Time it</Radio.Button>
                                    <Radio.Button value={3}>Anything can be timed</Radio.Button>

                                </Space>
                            </Radio.Group> :
                            <Radio.Group value={slideNum} onChange={handlePageChange} style={{ marginBottom: 16 }} buttonStyle="solid">
                                <Radio.Button value={0}>Just say it</Radio.Button>
                                <Radio.Button value={1}>Remember when?</Radio.Button>
                                <Radio.Button value={2}>Time it</Radio.Button>
                                <Radio.Button value={3}>Anything can be timed</Radio.Button>
                            </Radio.Group>
                        }

                        <SimulatedChat scrollToBottom={scrollToBottom} slideNum={slideNum} onNext={onNext} />

                    </div>


                </OverPack>
            </div >
        </div >
    );

};


export default ChatSimulationExamples;



// return (<div style={{
//     ...wrapperStyle, alignItems: 'center', margin: "auto"
// }}>
//     <div style={{ height: '100%', alignItems: 'center', margin: "auto" }} id='contentSim'>
//         <div style={titleContainerStyle}>
//             {/* {titleWrapper.children.map(getChildrenToRender)} */}
//             {titleWrapper.children.map((item, index) => getChildrenToRender(item, index))}
//         </div>
//         <OverPack {...overPackData}>



{/* <SimulatedChat scrollToBottom={this.props.scrollToBottom} slideNum={0} /> */ }

{/* <BannerAnim type="gridBar" prefixCls="banner-user" autoPlaySpeed={4000}> */ }

{/* <BannerAnim type="gridBar" prefixCls="banner-user">
                        <Element
                            prefixCls="banner-user-elem"
                            key="0"
                        >
                            <BgElement
                                key="bg"
                                className="bg"
                            />
                            <SimulatedChat scrollToBottom={this.props.scrollToBottom} slideNum={0} />
                        </Element>

                        <Element
                            prefixCls="banner-user-elem"
                            key="1"
                        >
                            <BgElement
                                key="bg"
                                className="bg"
                            />
                            <SimulatedChat scrollToBottom={this.props.scrollToBottom} slideNum={1} />
                        </Element>

                    </BannerAnim> */}